import React, { useState } from "react";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";

export default function SourceFilter(props) {
  const {
    filters: { name, newerThan, olderThan },
    setName,
    setNewerThan,
    setOlderThan
  } = props;

  const [active, setActive] = useState(false);

  if (!active)
    return (
      <Button
        large
        icon="search"
        text="Filter sources"
        onClick={() => setActive(true)}
      />
    );

  return (
    <div style={{ display: "flex" }}>
      <FormGroup style={{ marginRight: "1em" }} label="Filter by name:">
        <InputGroup
          large
          autoFocus
          leftIcon="search"
          placeholder="Name..."
          value={name || ""}
          onChange={e => setName(e.target.value)}
        />
      </FormGroup>
      <FormGroup style={{ marginRight: "1em" }} label="Newer than:">
        <DateInput
          canClearSelection
          inputProps={{
            large: true,
            leftIcon: "calendar"
          }}
          placeholder="Newer than..."
          formatDate={date => date.toDateString()}
          parseDate={str => new Date(str)}
          value={newerThan || null}
          onChange={date => setNewerThan(date)}
        />
      </FormGroup>
      <FormGroup style={{ marginRight: "1em" }} label="Older than:">
        <DateInput
          canClearSelection
          inputProps={{
            large: true,
            leftIcon: "calendar"
          }}
          placeholder="Older than..."
          formatDate={date => date.toDateString()}
          parseDate={str => new Date(str)}
          value={olderThan || null}
          onChange={date => setOlderThan(date)}
        />
      </FormGroup>
    </div>
  );
}
