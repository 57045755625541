import React, { useEffect, useContext } from "react";
import "styled-components/macro";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Button } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import { workspacesRef } from "../firebase";
import { PlayerContext } from "../contexts";
import SourceCards from "./SourceCards";
import Source from "../source/Source";
import PageNotFound from "../ui/PageNotFound";
import Favorites from "./Favorites";
import WorkspaceSettings from "./WorkspaceSettings";
import CenteredSection from "../ui/CenteredSection";
import { Header } from "../ui/headers";
import Helmet from "react-helmet";

export default function Workspace(props) {
  const {
    path,
    params: { workspaceId }
  } = useRouteMatch();

  const { loadedSourceId } = useContext(PlayerContext);

  const workspace = useSubscription(workspacesRef.doc(workspaceId));

  useEffect(() => {
    if (workspace.data && workspace.data.name) {
    }
  }, [workspace]);

  if (!workspace.ready) return null;

  if (!workspace.data)
    return (
      <p>
        Workspace <code>{workspaceId}</code> not found.
      </p>
    );

  const { deleted } = workspace.data;

  if (deleted)
    return (
      <CenteredSection>
        <Header>This workspace was deleted!</Header>
        <Button
          large
          intent="primary"
          text="Restore workspace"
          onClick={() => workspace.ref.update({ deleted: false })}
        />
      </CenteredSection>
    );

  return (
    <React.Suspense fallback={null}>
      {!loadedSourceId && (
        <Helmet>
          <title>{workspace.data.name} | TagDog</title>
        </Helmet>
      )}
      <Switch>
        <Route exact path={path} component={SourceCards} />
        <Route exact path={path + "/favorites"} component={Favorites} />
        <Route exact path={path + "/settings"} component={WorkspaceSettings} />
        <Route path={path + "/sources/:sourceId"} component={Source} />
        <Route component={PageNotFound} />
      </Switch>
    </React.Suspense>
  );
}
