import { useSubscription } from "react-firehook";
import { sourcesRef } from "../firebase";

export default function SourceName(props) {
  const { sourceId } = props;

  const { data } = useSubscription(sourceId && sourcesRef.doc(sourceId));

  if (!data) return null;

  const { name } = data;

  return name;
}
