import styled from "styled-components";

export const Header = styled.h1`
  font-size: 1.5em;
  font-weight: 600;
`;

export const SubHeader = styled(Header).attrs({
  as: "h2"
})`
  font-size: 1.4em;
`;
