import React, { useState } from "react";
import { Button, H4, Callout } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import { useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { workspacesRef } from "../firebase";
import { baseUrl, getUnguessableCode } from "../utils";
import { toast } from "../constants";

export default function WorkspaceInviteButton(props) {
  const { workspaceId } = useParams();
  const [showUrl, setShowUrl] = useState(false);

  const workspace = useSubscription(workspacesRef.doc(workspaceId));

  if (!workspace.ready) return null;

  const {
    invitationCode,
    name = "this workspace",
    memberUids
  } = workspace.data;

  const invitationUrl =
    invitationCode &&
    baseUrl + "/invitation/" + workspaceId + "/" + invitationCode;

  if (!showUrl)
    return (
      <Button
        large
        intent={memberUids.length === 1 ? "primary" : null}
        style={{ margin: "0 1em 0 0" }}
        icon="new-person"
        text="Invite people"
        onClick={async () => {
          if (!invitationCode)
            await workspace.ref.update({
              invitationCode: getUnguessableCode()
            });
          setShowUrl(true);
        }}
      />
    );

  return (
    <Callout style={{ margin: "1em 0 0 0" }}>
      <H4 style={{ marginBottom: "1em" }}>Invite people to {name}</H4>
      <p>Anyone can use the following URL to join:</p>
      <p>
        <strong>{invitationUrl}</strong>
      </p>
      <Button
        large
        style={{ margin: "0 0 1em 0" }}
        icon="clipboard"
        text="Copy URL"
        onClick={async () => {
          copy(invitationUrl);
          toast({
            message: "Copied to clipboard.",
            intent: "success"
          });
        }}
      />
      <p>
        If you want to prevent more people from joining using that URL, you can
        invalidate it:
      </p>
      <Button
        large
        // intent="primary"
        style={{ margin: "0 0 0.5em 0" }}
        icon="refresh"
        text="Invalidate URL"
        onClick={async () => {
          await workspace.ref.update({
            invitationCode: getUnguessableCode()
          });

          toast({
            message: "Invitation URL updated. Old invitations revoked.",
            intent: "success"
          });
        }}
      />
    </Callout>
  );
}
