import React from "react";
import styled, { keyframes } from "styled-components";

export function fadeInAnimation(props) {
  return keyframes`
    from {
      ${props.from}
      opacity: 0;
    }

    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  `;
}

const FadeIn = styled.div`
  animation: ${fadeInAnimation} ${props => props.length || 0.5}s ease normal
    forwards running;
  ${props => props.from}
`;

export default ({ translateFrom, ...rest }) => (
  <FadeIn
    from={translateFrom ? `transform: translate(${translateFrom});` : ""}
    {...rest}
  />
);
