import React from "react";
import formatDuration from "format-duration";
import { hidden } from "ansi-colors";

export default function ProgressField(props) {
  const {
    compact,
    length,
    time,
    height,
    active,
    onClick,
    noLabels,
    innerText,
    transparent
  } = props;

  const progress = active && length ? time / length : 0;

  return (
    <div title={compact ? null : active ? "Go to location" : "Play mark"}>
      {length && !compact && !noLabels && (
        <div style={{ display: "flex" }}>
          {[...Array(Math.floor(length / (60 * 60))).keys()].map(i => (
            <div key={i} style={{ width: ((60 * 60) / length) * 100 + "%" }}>
              {!!i && i + "h"}
            </div>
          ))}
          <div
            style={{
              width: ((length % (60 * 60)) / length) * 100 + "%",
              display: "flex",
              overflow: "hidden"
            }}
          >
            <div style={{ flexGrow: 1 }}>
              {length > 60 * 60 && Math.floor(length / (60 * 60)) + "h"}
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          cursor: "pointer",
          width: "100%",
          borderRadius: !compact && "5px",
          backgroundColor: !compact && !transparent && "#eee",
          whiteSpace: "nowrap"
        }}
        onClick={event => {
          let newTime;
          if (active) {
            const rect = event.currentTarget.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const frac = x / rect.width;
            newTime = length * frac;
          } else newTime = 0;

          onClick && onClick(newTime);
        }}
      >
        <>
          <div
            style={{
              backgroundColor: !transparent && (compact ? "white" : "#aaa"),
              width: progress * 100 + "%",
              overflow: "hidden",
              paddingTop: !compact && "0.5em",
              fontWeight: "bold",
              borderRadius: "5px",
              display: "flex",
              height
            }}
          >
            <span
              style={{
                flexGrow: 1,
                overflow: "hidden",
                marginLeft: "0.5em",
                height
              }}
            >
              {innerText}
            </span>

            {!compact && (
              <span style={{ paddingRight: "0.5em" }}>
                {active && length !== null && time / length >= 0.5
                  ? formatDuration(time * 1000)
                  : "🐕"}
              </span>
            )}
          </div>
          <div
            style={{
              height,
              width: (1 - progress) * 100 + "%",
              overflow: "hidden",
              paddingTop: !compact && "0.5em",
              fontWeight: "bold",
              display: "flex"
            }}
          >
            {!compact && (
              <span style={{ paddingLeft: "0.5em", flexGrow: 1, height }}>
                {active && length !== null && time / length < 0.5
                  ? formatDuration(time * 1000)
                  : "🐕"}
              </span>
            )}

            {!compact && (
              <span style={{ paddingRight: "0.5em", overflow: hidden, height }}>
                {length !== null && !isNaN(length)
                  ? formatDuration(length * 1000)
                  : ""}
              </span>
            )}
          </div>
        </>
      </div>
    </div>
  );
}
