import React, { useState, useEffect } from "react";
import { EditableText } from "@blueprintjs/core";

export default function LiveEditableText(props) {
  const { defaultValue, value: propsValue, onChange, ...rest } = props;

  if (propsValue || onChange)
    throw new Error("LiveEditableText does not accept value or onChange");

  const [actualValue, setActualValue] = useState(defaultValue);

  useEffect(() => setActualValue(defaultValue), [defaultValue]);

  return (
    <EditableText value={actualValue} onChange={setActualValue} {...rest} />
  );
}
