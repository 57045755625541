import React, { useContext } from "react";
import { InputGroup } from "@blueprintjs/core";
import { SettingsFormContext } from "../../contexts";

export default function SettingsStringInput(props) {
  const { label, id, ...rest } = props;

  const {
    editing,
    setEditing,
    value,
    setValue,
    defaultValue,
    handleSave
  } = useContext(SettingsFormContext);

  return (
    <tr style={{ margin: "1em 0" }}>
      <td>{label}</td>
      <td>
        {editing ? (
          <InputGroup
            large
            value={value[id]}
            onChange={event =>
              setValue(value => ({ ...value, [id]: event.target.value }))
            }
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleSave();
              }
              if (event.key === "Escape") {
                setEditing(false);
              }
            }}
            {...rest}
          />
        ) : (
          <strong style={{ fontSize: "1.1em" }}>{defaultValue[id]}</strong>
        )}
      </td>
    </tr>
  );
}
