import React, { useContext } from "react";
import { Slider } from "@blueprintjs/core";
import { SettingsFormContext } from "../../contexts";

export default function SettingsHueInput(props) {
  const { label, id, ...rest } = props;

  const { editing, value, setValue, defaultValue } = useContext(
    SettingsFormContext
  );

  return (
    <tr>
      <td>{label}</td>
      <td>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "15px",
              backgroundColor: `hsl(${value[id]}, 100%, 50%)`,
              marginRight: "2em"
            }}
          >
            &nbsp;
          </div>
          {editing && (
            <div style={{}}>
              <Slider
                min={0}
                max={360}
                stepSize={1}
                labelRenderer={() => {}}
                value={editing ? value[id] : defaultValue[id]}
                onChange={hue =>
                  setValue(oldValue => ({ ...oldValue, [id]: hue }))
                }
                {...rest}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
