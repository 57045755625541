import React, { useContext, useEffect, useRef } from "react";
import "styled-components/macro";
import { Card, H4, H1, Icon } from "@blueprintjs/core";
import { history } from "../constants";
import { workspacesRef } from "../firebase";
import { UserContext, PlayerContext } from "../contexts";
import { useSubscription } from "react-firehook";
import FadeIn from "../ui/FadeIn";
import pluralize from "pluralize";
import CenteredSection from "../ui/CenteredSection";
import Helmet from "react-helmet";

function WorkspaceCard(props) {
  const {
    workspaceId,
    workspace: {
      name,
      memberUids,
      hue,
      sourcesCount,
      marksCount,
      backgroundImage = {}
    }
  } = props;

  useEffect(() => {
    // TODO: migration - remove
    if (!hue)
      workspacesRef.doc(workspaceId).update({
        hue: Math.floor(Math.random() * 360)
      });
  }, [hue, workspaceId]);

  const { current: rotation } = useRef((Math.random() - 0.5) * 5);

  return (
    <Card
      key={workspaceId}
      interactive
      elevation={2}
      css={`
        margin: 0 2em 2em 0;
        background-color: hsl(${hue}, 20%, 98%);
        width: 20em;
        padding: 0;

        /* transition: transform 0.5s ease;

        &:hover {
          transform: rotate(${rotation}deg);
        } */

        @media (max-width: 500px) {
          width: 15em;
        }
      `}
      onClick={() => history.push("/workspaces/" + workspaceId)}
    >
      {backgroundImage.url ? (
        <img
          src={backgroundImage.url}
          alt=""
          css={`
            width: 100%;
            height: auto;

            @media (max-width: 500px) {
              display: none;
            }
          `}
        />
      ) : (
        <Icon
          icon="desktop"
          iconSize={60}
          css={`
            padding: 6em 0 3em;

            @media (max-width: 500px) {
              display: none;
            }
          `}
        />
      )}
      <div
        css={`
          padding: 2em 1em;
        `}
      >
        <H4>{name || "Unnamed workspace"}</H4>
        <p>
          <CountItem singular="member" icon="user" count={memberUids.length} />
          {!!sourcesCount && (
            <CountItem singular="source" icon="pulse" count={sourcesCount} />
          )}
          {!!marksCount && (
            <CountItem singular="mark" icon="bookmark" count={marksCount} />
          )}
        </p>
      </div>
    </Card>
  );
}

function CountItem(props) {
  const { singular, icon, count } = props;

  return (
    <span
      style={{ marginRight: "0.8em" }}
      title={pluralize(singular, count, true)}
    >
      {count} <Icon icon={icon} />
    </span>
  );
}

export default function Dashboard(props) {
  const { uid } = useContext(UserContext);
  const { loadedSourceId } = useContext(PlayerContext);

  const { ready, data } = useSubscription(
    workspacesRef
      .where("memberUids", "array-contains", uid)
      .where("deleted", "==", false)
  );

  if (!ready) return null;

  return (
    <FadeIn
      style={{
        textAlign: "center",
        color: "#aaa"
      }}
      css={`
        margin: 3em 0 0;
        @media (max-width: 800px) {
          margin-top: 0;
        }
      `}
    >
      {!loadedSourceId && (
        <Helmet>
          <title>TagDog</title>
        </Helmet>
      )}

      <CenteredSection>
        <H1
          css={`
            text-align: center;
            margin-bottom: 1em;

            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          {!data || !Object.keys(data).length
            ? "You don't have any workspaces"
            : "Select workspace "}
        </H1>

        {data && !!Object.keys(data).length && (
          <section
            style={{
              margin: "0 0 0",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            {Object.keys(data).map(workspaceId => {
              return (
                <WorkspaceCard
                  key={workspaceId}
                  workspaceId={workspaceId}
                  workspace={data[workspaceId]}
                />
              );
            })}
          </section>
        )}
      </CenteredSection>
    </FadeIn>
  );
}
