import React, { useState, useEffect, useContext } from "react";
import "styled-components/macro";
import { Router, Route, Switch } from "react-router-dom";
import { useSubscription } from "react-firehook";
import { auth, profilesRef } from "../firebase";
import { history } from "../constants";
import { UserContext, UserProfileContext } from "../contexts";
import PageNotFound from "../ui/PageNotFound";
import TotalCenter from "../ui/TotalCenter";
import Hourglass from "../ui/Hourglass";
import Workspace from "../workspace/Workspace";
import Account from "../user/Account";
import Dashboard from "../user/Dashboard";
import Navbar from "./navbar/Navbar";
import PlayerProvider from "./player/PlayerProvider";
import CreateProfileScreen from "./CreateProfileScreen";
import AppFooter from "./AppFooter";
import WorkspaceJoiner from "./WorkspaceJoiner";
import LandingPage from "./LandingPage";

function UserBarrier(props) {
  const user = useContext(UserContext);

  if (user === undefined)
    return (
      <TotalCenter>
        <Hourglass />
      </TotalCenter>
    );

  if (user === null) return <LandingPage />;

  return props.children;
}

function App() {
  const [user, setUser] = useState();
  useEffect(() => auth.onAuthStateChanged(setUser), []);

  const userProfile = useSubscription(
    user && user.uid && profilesRef.doc(user.uid)
  );

  if (userProfile.ready && !userProfile.data)
    return <CreateProfileScreen user={user} />;

  return (
    <Router history={history}>
      <UserContext.Provider value={user}>
        <UserBarrier>
          <UserProfileContext.Provider value={userProfile.data || {}}>
            <PlayerProvider>
              <Route
                path={[
                  "/workspaces/:workspaceId/sources/:sourceId",
                  "/workspaces/:workspaceId",
                  "**"
                ]}
                component={Navbar}
              />
              <main>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/account" component={Account} />
                  <Route
                    path="/workspaces/:workspaceId"
                    component={Workspace}
                  />
                  <Route
                    exact
                    path={"/invitation/:workspaceId/:invitationCode"}
                    component={WorkspaceJoiner}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </main>

              <AppFooter />
            </PlayerProvider>
          </UserProfileContext.Provider>
        </UserBarrier>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
