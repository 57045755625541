import React, { useState } from "react";
import styled from "styled-components";
import { TotalCenter } from "@josteinbe/reager";
import { H1, InputGroup, Button } from "@blueprintjs/core";
import { profilesRef } from "../firebase";

const StyledHeader = styled(H1)`
  margin-bottom: 1em;
  font-weight: 600;
`;

const StyledHelpText = styled.p`
  font-weight: 600;
  font-size: 0.9em;
  color: grey;
  margin: 0.5em 0 2em;
`;

export default function CreateProfileScreen(props) {
  const {
    user: { uid }
  } = props;

  const [displayName, setDisplayName] = useState();

  const isValid = displayName && !/^\s*$/.test(displayName);

  return (
    <TotalCenter>
      <div>
        <StyledHeader>Create profile</StyledHeader>
        <StyledHeader as="p">Please choose a user name:</StyledHeader>
        <InputGroup
          large
          placeholder="Enter user name..."
          value={displayName}
          onChange={event => setDisplayName(event.target.value)}
          onKeyDown={event => {
            if (isValid && event.key === "Enter") {
              profilesRef.doc(uid).set({
                displayName
              });
            }
          }}
        />
        <StyledHelpText>Your collaborators will see this.</StyledHelpText>
        <Button
          large
          intent="primary"
          text="Save"
          disabled={!isValid}
          onClick={() => {
            profilesRef.doc(uid).set({
              displayName
            });
          }}
        />
      </div>
    </TotalCenter>
  );
}
