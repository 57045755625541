import React, { useContext, useState, useRef, useEffect } from "react";
import "styled-components/macro";
import { Menu, MenuItem, MenuDivider, Icon, Card } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import { workspacesRef } from "../../firebase";
import { UserContext, UserProfileContext } from "../../contexts";
import { history } from "../../constants";
import { useParams } from "react-router-dom";
import { createWorkspace } from "../../actions";
import { CrumbNavLink } from "./NavItems";

export default function WorkspacePicker() {
  const { workspaceId } = useParams();

  const { uid } = useContext(UserContext);
  const { displayName } = useContext(UserProfileContext);

  const { ready, data } = useSubscription(
    workspacesRef
      .where("memberUids", "array-contains", uid)
      .where("deleted", "==", false)
  );

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  const onClickOutside = e =>
    menuRef.current && !menuRef.current.contains(e.target) && setIsOpen(false);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  if (!ready) return null;

  const workspace = data && data[workspaceId];

  if (!workspace)
    return (
      <CrumbNavLink className="active" as="div">
        Page not found
      </CrumbNavLink>
    );

  return (
    <>
      <CrumbNavLink
        className="active"
        as="div"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(v => !v)}
      >
        {workspace.name || "Unnamed workspace"} <Icon icon="chevron-down" />
      </CrumbNavLink>
      {isOpen && (
        <Card
          css={`
            position: absolute;
            z-index: 10000;
            padding: 0;
            margin: 4em 0 0 0;

            a {
              color: black !important;
            }
          `}
        >
          <div ref={menuRef}>
            <Menu large>
              {Object.keys(data).map(id => (
                <MenuItem
                  key={id}
                  disabled={id === workspaceId}
                  text={data[id].name || "Unnamed workspace"}
                  onClick={() => {
                    setIsOpen(false);
                    history.push("/workspaces/" + id);
                  }}
                />
              ))}
              <MenuDivider />

              <MenuItem
                icon="plus"
                text="New workspace"
                onClick={async () => {
                  setIsOpen(false);
                  const doc = await createWorkspace(uid, displayName);
                  history.push("/workspaces/" + doc.id);
                }}
              />
            </Menu>
          </div>
        </Card>
      )}
    </>
  );
}
