import React from "react";
import { Button, MenuItem, ButtonGroup } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import {
  stringIncludeIgnoreCase,
  stringMenuItemRenderer
} from "../../ui/helpers/selectHelpers";
import { useWindowDimensions } from "@josteinbe/reager";

export default function TagFilterPicker(props) {
  const { selectedItem, ...rest } = props;

  const { width: windowWidth } = useWindowDimensions();

  return (
    <ButtonGroup
      css={`
        margin: 1em 1em 0 0;
      `}
    >
      <Select
        activeItem={selectedItem}
        itemPredicate={stringIncludeIgnoreCase}
        itemRenderer={stringMenuItemRenderer}
        inputProps={{ placeholder: "Find tag..." }}
        noResults={<MenuItem disabled={true} text="No results" />}
        {...rest}
      >
        <Button
          large
          minimal
          icon="tag"
          rightIcon="caret-down"
          text={selectedItem || (windowWidth > 500 && "Filter by tag")}
        />
      </Select>
      {selectedItem && (
        <Button
          minimal
          large
          icon="cross"
          title="Clear filter"
          onClick={() => props.onItemSelect("")}
        />
      )}
    </ButtonGroup>
  );
}
