import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router";
import { NonIdealState, Button, ProgressBar } from "@blueprintjs/core";
import { useWindowDimensions } from "@josteinbe/reager";
import { PlayerTimeContext, PlayerContext } from "../../contexts";
import { history } from "../../constants";
import { useSubscription } from "react-firehook";
import { sourcesRef, marksRef } from "../../firebase";
import { generateWaveformForExistingSource } from "../../actions";

function useWaveformData(sourceId) {
  const [waveform, setWaveform] = useState();

  useEffect(() => {
    let mounted = true;

    async function fetchWaveform() {
      const response = await fetch(
        `https://storage.googleapis.com/band-aid-ff041.appspot.com/waveforms/${sourceId}.peaks`
      );
      if (mounted && response.status === 404) {
        setWaveform("404");
      }
      if (!response.ok || !mounted) return;
      const buffer = await response.arrayBuffer();
      if (!mounted) return;
      setWaveform(new Uint8Array(buffer));
    }

    fetchWaveform();

    return () => (mounted = false);
  }, [sourceId]);

  return waveform;
}

export default function Waveform(props) {
  const {
    height = 75,
    onClick,
    onMarkClick,
    colorizeMarks,
    colorIndex,
    startTime,
    endTime,
    highlightStart,
    highlightEnd
  } = props;
  const { sourceId, workspaceId } = useParams();

  const playerTime = useContext(PlayerTimeContext);
  const { loadedSourceId } = useContext(PlayerContext);

  const source = useSubscription(sourcesRef.doc(sourceId));

  if (endTime && (startTime || 0) >= endTime) {
    throw new Error("startTime must be before endTime");
  }

  if (colorizeMarks && colorIndex !== undefined) {
    throw new Error(
      "cannot colorize marks and use color index at the same time"
    );
  }

  let marksQuery = marksRef
    .where("sourceId", "==", sourceId)
    .where("workspaceId", "==", workspaceId)
    .orderBy("time");

  if (startTime) marksQuery = marksQuery.where("time", ">=", startTime);
  if (endTime) marksQuery = marksQuery.where("time", "<=", endTime);

  const marks = useSubscription(!!colorizeMarks && sourceId && marksQuery);
  const waveform = useWaveformData(sourceId);
  const canvasRef = useRef();
  const { width: windowWidth } = useWindowDimensions();

  const { length: sourceLength = 0 } = source.data || {};
  const waveformLength = (endTime || sourceLength) - (startTime || 0);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (
      !canvas ||
      !waveform ||
      waveform === "404" ||
      !source.data ||
      !source.data.length
    )
      return;
    const marksData = marks.data || {};

    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext("2d");

    canvas.width = rect.width;

    const sourceIsLoaded = sourceId === loadedSourceId;

    const totalSamples = waveform.length;

    const samplesPerPixel = Math.floor(totalSamples / rect.width) || 1;
    const timePerPixel = waveformLength / rect.width;

    const halfHeight = canvas.height / 2;

    ctx.clearRect(0, 0, rect.width, canvas.height);

    for (let x = 0; x < rect.width; x++) {
      const pixelTime = (startTime || 0) + x * timePerPixel;

      const sampleIndex = Math.floor(
        (pixelTime / sourceLength) * waveform.length
      );

      let maxAmp = 0; // find loudest sample in pixel
      for (let offset = 0; offset < samplesPerPixel; offset++) {
        const amp = waveform[sampleIndex + offset];
        if (amp > maxAmp) maxAmp = amp;
      }

      const amp = maxAmp / 128;

      const adjustedAmp = amp * 0.5 * canvas.height;
      const inThePast = sourceIsLoaded && playerTime > pixelTime;

      const activeMarks = Object.keys(marksData).filter(markId => {
        const mark = marksData[markId];

        return pixelTime > mark.time && pixelTime < mark.time + mark.length;
      });

      let color = inThePast ? "#444" : "#aaa";
      const saturation = inThePast ? "100%" : "50%";
      const lightness = inThePast ? "20%" : "70%";

      if (activeMarks.length) {
        const activeMarkId = activeMarks[activeMarks.length - 1];
        const activeMarkIndex = Object.keys(marksData).indexOf(activeMarkId);
        color =
          "hsl(" +
          activeMarkIndex * 51 +
          ", " +
          saturation +
          ", " +
          lightness +
          ")";
      }

      if (colorIndex !== undefined) {
        color =
          "hsl(" + colorIndex * 51 + ", " + saturation + ", " + lightness + ")";
      }

      if (
        highlightStart !== undefined &&
        highlightEnd &&
        pixelTime > highlightStart &&
        pixelTime < highlightEnd
      ) {
        ctx.strokeStyle = "#000";
        ctx.beginPath();
        ctx.moveTo(x + 0.5, 0);
        ctx.lineTo(x + 0.5, canvas.height);
        ctx.stroke();
        ctx.closePath();
      }

      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(x + 0.5, halfHeight - adjustedAmp);
      ctx.lineTo(x + 0.5, halfHeight + adjustedAmp);
      ctx.stroke();
      ctx.closePath();
    }

    const hours = Math.floor(waveformLength / (60 * 60));
    ctx.strokeStyle = "#55f";
    ctx.lineWidth = 2;

    for (let h = 1; h <= hours; h++) {
      const x = (h * 60 * 60) / timePerPixel;
      ctx.beginPath();
      ctx.moveTo(x + 0.5, 0);
      ctx.lineTo(x + 0.5, height);
      ctx.stroke();
      ctx.closePath();
    }
    ctx.lineWidth = 1;
  }, [
    canvasRef,
    waveform,
    source.data,
    marks.ready,
    marks.data,
    loadedSourceId,
    sourceId,
    playerTime,
    windowWidth,
    height,
    endTime,
    startTime,
    sourceLength,
    waveformLength,
    colorIndex,
    highlightStart,
    highlightEnd
  ]);

  const [regenerating, setRegenerating] = useState(false);

  if (waveform === "404") {
    return (
      <div
        style={{
          margin: "2em 0 7em"
        }}
      >
        <NonIdealState
          title="Waveform not available"
          description={
            regenerating
              ? "⏳ Regenerating waveform..."
              : "Click the button to generate waveform data!"
          }
          action={
            regenerating ? (
              <ProgressBar />
            ) : (
              <Button
                large
                intent="primary"
                icon="pulse"
                text="Regenerate waveform"
                onClick={async () => {
                  setRegenerating(true);
                  await generateWaveformForExistingSource(
                    workspaceId,
                    sourceId,
                    source.data ? source.data.name : "Audio"
                  );

                  history.push("/workspaces/" + workspaceId);
                  setTimeout(() =>
                    history.replace(
                      ["/workspaces", workspaceId, "sources", sourceId].join(
                        "/"
                      )
                    )
                  );
                }}
              />
            )
          }
        />
      </div>
    );
  }

  if (!waveform || !source.data) return null;

  return (
    <div>
      <canvas
        style={{
          width: "100%",
          borderRadius: "5px",
          // marginTop: -height + "px",
          cursor: !!onClick && "pointer"
        }}
        height={height}
        ref={canvasRef}
        onClick={event => {
          let clickPixelTime;
          if (
            onClick &&
            source.data &&
            source.data.length
            // && loadedSourceId === sourceId
          ) {
            const rect = event.currentTarget.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const frac = x / rect.width;
            clickPixelTime = (startTime || 0) + waveformLength * frac;
          } else clickPixelTime = startTime || 0;

          if (onMarkClick && marks.data) {
            const activeMarks = Object.keys(marks.data).filter(markId => {
              const mark = marks.data[markId];
              return (
                clickPixelTime > mark.time &&
                clickPixelTime < mark.time + mark.length
              );
            });
            const clickedMark =
              activeMarks.length && activeMarks[activeMarks.length - 1];

            if (clickedMark) {
              onMarkClick(clickedMark);
              return;
            }
          }
          onClick && onClick(clickPixelTime);
        }}
      />
    </div>
  );
}
