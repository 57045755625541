import React from "react";
import styled from "styled-components";
import { auth, provider } from "../firebase";
import waveformImage from "./assets/waveform.png";

export const dogYellow = "#EEE579";

const Button = styled.button.attrs((props) => ({
  className: ["Button", props.importance || "secondary", props.className]
}))`
  text-decoration: none;
  border-radius: 5px;
  padding: 0.5em 1em 0.7em;
  margin-right: 1em;
  font-weight: 600;
  font-family: inherit;
  font-size: 17px;
  background-color: transparent;

  cursor: pointer;
  user-select: none;
  appearance: none;
  border: none;
  text-align: center;

  &.primary {
    background-color: #a00;
    color: #fff;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: none;
  }

  &.secondary {
    color: #a00;
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9); */
  }
`;

// const LinkButton = styled(Button).attrs(props => ({
//   as: "a"
// }))`
//   &:hover {
//     color: ${dogYellow};
//   }

//   &.primary {
//     &:hover {
//       color: black;
//     }
//   }
// `;

function Emoji(props) {
  const { emoji, ...rest } = props;

  return (
    <span role="img" {...rest}>
      {emoji}
    </span>
  );
}

export default function LandingPage(props) {
  return (
    <div>
      <Styles
        style={
          {
            // backgroundImage: `url(${bgImage})`
          }
        }
      >
        <div className="full-width">
          <section className="hero-content">
            <h1>
              Welcome to TagDog&nbsp;
              <Emoji
                emoji="🐶"
                aria-label="dog face"
                style={{ fontSize: "1em" }}
              />
            </h1>

            <div className="text">
              <p>
                TagDog lets you upload long recordings, such as jam sessions,
                interviews, meetings, etc, and share them with others through
                workspaces.
              </p>

              <p>
                You can timestamp interesting areas and add searchable metadata,
                so that you still find those timestamps much later.
              </p>

              {/* <p>
                You can even publish clips of your favorite timestamps onto the
                public internet.
              </p> */}
            </div>

            <ul className="nav">
              <li>
                <Button
                  importance="primary"
                  onClick={() => auth.signInWithPopup(provider)}
                  style={{ fontSize: "1.5em" }}
                >
                  Sign up
                </Button>
              </li>
              {/* <li style={{ fontSize: "1em", padding: "1em" }}>Free for now.</li> */}
              <li>
                <Button
                  importance="secondary"
                  onClick={() => auth.signInWithPopup(provider)}
                  style={{ fontSize: "1.5em" }}
                >
                  Log in
                </Button>
              </li>
            </ul>
          </section>
        </div>

        <div className="full-width">
          <div
            role="img"
            aria-label="waveform with coloured regions"
            className="waveform-image"
            style={{
              backgroundImage: "url('" + waveformImage + "')"
            }}
          />
        </div>

        <div className="full-width">
          <div className="hero-content" style={{ marginTop: "0" }}>
            <div className="text" style={{ marginTop: "3em" }}>
              <h3>
                Free for now{" "}
                <Emoji
                  emoji="🎁"
                  aria-label="wrapped gift"
                  style={{ fontSize: "1em" }}
                />
              </h3>
              <p>
                TagDog is completely free &ndash; you can upload and share as
                many mp3's as you like. If we start getting a lot of traffic, we
                might start charging a relatively small subscription fee.
              </p>

              <h3>
                Live collaboration{" "}
                <Emoji
                  emoji="📡"
                  aria-label="sattelite antenna"
                  style={{ fontSize: "1em" }}
                />
              </h3>
              <p>
                When you invite other members to a workspace, you can mark
                timestamps together, sharing changes in real-time.
              </p>

              <h3>
                Remember interesting locations{" "}
                <Emoji
                  emoji="🏷️"
                  aria-label="label tag"
                  style={{ fontSize: "1em" }}
                />
              </h3>
              <p>
                Add ratings, tags, descriptions and comments to your marked
                timestamps, so that they don't get lost in the endless sea of
                recordings.
              </p>
            </div>
          </div>
        </div>
      </Styles>
    </div>
  );
}

const Styles = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  color: #333;
  align-items: center;

  font-weight: 600;
  font-size: 17px;

  line-height: 1.618;

  nav {
    display: flex;
  }

  nav ul,
  ul.nav {
    display: flex;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  h1,
  h2 {
    margin: 0;
    /* font-family: "Yrsa", serif; */
    font-weight: 700;
    font-size: 3em;

    @media (max-width: 800px) {
      font-size: 2em;
    }
  }

  h1 a {
    color: #333;
    text-decoration: none;
  }

  .full-width {
    align-self: stretch;
  }

  nav {
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 2em 2em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9); */

    ul {
      margin: 0 0 15px auto;
    }
  }

  h3 {
    margin: 2em 0 0;
  }

  .waveform-image {
    margin: 0 1em;
    background-repeat: no-repeat;
    height: 100px;

    @media (min-width: 1500px) {
      background-position: center;
    }
  }

  .hero-content {
    margin: 5vw auto 0;
    padding: 2em;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    font-weight: 500;
    /* align-items: center; */

    @media (max-width: 600px) {
      font-size: 1em;
      padding: 1em;
    }

    p {
      margin: 1em 0;
      /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 1); */
    }

    ul {
      margin: 3em 0;
    }

    .text {
      max-width: 600px;
    }
  }
`;
