import React, { useState, useContext } from "react";
import { Alert, Button, AnchorButton } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { useSubscription } from "react-firehook";
import { workspacesRef, FieldValue } from "../firebase";
import { toast, history } from "../constants";
import WorkspaceMembers from "./WorkspaceMembers";
import CenteredSection from "../ui/CenteredSection";
import { Header, SubHeader } from "../ui/headers";
import {
  HeaderSection,
  MainPane,
  MenuSection,
  MainSection,
  SettingsCard
} from "../ui/settings/settingsStyles";
import SettingsSection from "../ui/settings/SettingsSection";
import SettingsStringInput from "../ui/settings/SettingsStringInput";
import SettingsHueInput from "../ui/settings/SettingsHueInput";
import WorkspaceInviteButton from "./WorkspaceInviteButton";
import FadeIn from "../ui/FadeIn";
import SourceLoadedBarrier from "../source/SourceLoadedBarrier";
import { UserContext } from "../contexts";

export default function WorkspaceSettings() {
  const { workspaceId } = useParams();
  const { ref, ready, data } = useSubscription(workspacesRef.doc(workspaceId));

  if (!ready) return null;

  const { name, hue, memberUids, backgroundImage = {} } = data;

  return (
    <FadeIn>
      <CenteredSection>
        <HeaderSection>
          <Header>Settings for {name || "Unnamed workspace"}</Header>
          <p>
            {memberUids.length} member{memberUids.length !== 1 && "s"}
          </p>
        </HeaderSection>

        <MainPane>
          <MenuSection>
            <AnchorButton minimal href="#name" text="Name and appearance" />
            <AnchorButton minimal href="#members" text="Members" />
            <AnchorButton minimal href="#danger" text="Danger zone" />
          </MenuSection>

          <MainSection>
            <SettingsSection
              id="name"
              defaultValue={{
                name,
                hue,
                "backgroundImage.url": backgroundImage.url || ""
              }}
              header="Name and appearance"
              onSave={async fields => {
                await ref.update(fields);
                toast({
                  message: "Workspace updated",
                  intent: "success"
                });
              }}
            >
              <SettingsStringInput
                label="Name:"
                id="name"
                placeholder="Unnamed workspace"
              />
              <SettingsHueInput label="Hue:" id="hue" />
              <SettingsStringInput
                label="Image URL:"
                id="backgroundImage.url"
                placeholder="No image"
              />
            </SettingsSection>

            <SettingsCard id="members">
              <SubHeader>Members</SubHeader>
              <WorkspaceMembers editable />
              <div style={{ margin: "1em 1em 0 0" }}>
                <WorkspaceInviteButton />
                {memberUids.length > 1 && <LeaveWorkspaceButton />}
              </div>
            </SettingsCard>

            <SettingsCard id="danger">
              <SubHeader>Danger zone</SubHeader>
              <SourceLoadedBarrier context="workspace">
                <DeleteWorkspaceButton />
              </SourceLoadedBarrier>
            </SettingsCard>
          </MainSection>
        </MainPane>
      </CenteredSection>
    </FadeIn>
  );
}

function DeleteWorkspaceButton() {
  const { workspaceId } = useParams();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        large
        intent="danger"
        text="Delete workspace"
        onClick={() => setOpen(true)}
      />
      <Alert
        canEscapeKeyCancel
        canOutsideClickCancel
        isOpen={open}
        onClose={() => setOpen(false)}
        confirmButtonText="Delete workspace"
        icon="trash"
        intent="danger"
        cancelButtonText="Cancel"
        onConfirm={() => {
          workspacesRef.doc(workspaceId).update({ deleted: true });
          history.push("/");
          toast({ message: "Workspace deleted", intent: "success" });
        }}
      >
        Are you sure you want to delete this workspace? You will lose all the
        audio sources and saved marks. All members will lose access to the
        workspace.
      </Alert>
    </>
  );
}

function LeaveWorkspaceButton() {
  const { workspaceId } = useParams();
  const [open, setOpen] = useState(false);
  const { uid } = useContext(UserContext);

  return (
    <>
      <Button
        large
        icon="walk"
        text="Leave workspace"
        onClick={() => setOpen(true)}
      />
      <Alert
        canEscapeKeyCancel
        canOutsideClickCancel
        isOpen={open}
        onClose={() => setOpen(false)}
        confirmButtonText="Leave workspace"
        icon="walk"
        intent="danger"
        cancelButtonText="Cancel"
        onConfirm={() => {
          workspacesRef.doc(workspaceId).update({
            memberUids: FieldValue.arrayRemove(uid),
            ["memberNames." + uid]: null
          });
          history.push("/");
          toast({
            message: "You are no longer part of this workspace.",
            intent: "success"
          });
        }}
      >
        Are you sure you want to leave this workspace?
      </Alert>
    </>
  );
}
