import React, { useContext } from "react";
import "styled-components/macro";
import { useParams } from "react-router";
import MarksViz, { WaveformMarkLabels } from "../MarksViz";
import Waveform from "./Waveform";
import styled from "styled-components";
import { PlayerContext } from "../../contexts";

export default function WaveformNavigator() {
  const { sourceId, workspaceId } = useParams();
  const { goToLocation } = useContext(PlayerContext);

  return (
    <Styles>
      <Waveform
        colorizeMarks
        onClick={newTime => goToLocation(workspaceId, sourceId, newTime, true)}
      />
      <MarksViz sourceId={sourceId} workspaceId={workspaceId} />
      <div className="labels" style={{}}>
        <WaveformMarkLabels workspaceId={workspaceId} sourceId={sourceId} />
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  margin: 1em 1em 2em;

  /* @media (hover: hover) { */
  .labels {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 1em 2em;
    pointer-events: none;
  }
  /* } */

  &:hover .labels {
    display: block;
  }
`;
