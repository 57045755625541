import React, { useEffect, useContext } from "react";
import "styled-components/macro";
import { useSubscription } from "react-firehook";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useQueryString, useWindowDimensions } from "@josteinbe/reager";
import Mark from "../mark/Mark";
import PageNotFound from "../ui/PageNotFound";
import MarksTable from "./MarksTable";
import WaveformNavigator from "./progress/WaveformNavigator";
import { PlayerContext } from "../contexts";
import CenteredSection from "../ui/CenteredSection";
import { sourcesRef, workspacesRef } from "../firebase";
import SourceCard from "./SourceCard";
import Helmet from "react-helmet";
const SourceEditor = React.lazy(() => import("./SourceEditor"));

export default function Source(props) {
  const {
    path,
    params: { workspaceId, sourceId }
  } = useRouteMatch();

  const { goToLocation, loadedSourceId } = useContext(PlayerContext);

  const source = useSubscription(sourcesRef.doc(sourceId));
  const workspace = useSubscription(workspacesRef.doc(workspaceId));

  const query = useQueryString();
  const { t } = query;

  const { width: windowWidth } = useWindowDimensions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => t && goToLocation(workspaceId, sourceId, t, true), []);

  return (
    <div>
      {!loadedSourceId && source.data && workspace.data && (
        <Helmet>
          <title>
            {source.data.name + " – " + workspace.data.name + " | TagDog"}
          </title>
        </Helmet>
      )}

      <Switch>
        <Route exact path={[path]}>
          <WaveformNavigator />
          <CenteredSection style={{ display: "flex", minHeight: "16em" }}>
            <div style={{ flexGrow: "1" }}></div>
            {source.data && windowWidth > 1200 && (
              <SourceCard sourceId={sourceId} sourceData={source.data} />
            )}
          </CenteredSection>
          <MarksTable />
        </Route>
        <Route exact path={path + "/settings"} component={SourceEditor} />
        <Route exact path={path + "/marks/:markId"} component={Mark} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}
