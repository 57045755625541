import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { PlayerContext } from "../contexts";
import { Button } from "@blueprintjs/core";
import FadeIn from "../ui/FadeIn";

export default function SourceLoadedBarrier(props) {
  const { children, context = "source" } = props;
  const { sourceId, workspaceId } = useParams();
  const { loadedSourceId, loadedSourceWorkspaceId, eject } = useContext(
    PlayerContext
  );

  if (
    (context === "source" && sourceId === loadedSourceId) ||
    (context === "workspace" && workspaceId === loadedSourceWorkspaceId)
  )
    return (
      <>
        <div>
          <p>
            {context === "source" && (
              <span>This source is currently loaded into the player.</span>
            )}
            {context === "workspace" && (
              <span>A source in this workspace is currently playing.</span>
            )}{" "}
            Some settings are only available when the source is ejected.
          </p>
          <Button large icon="eject" text="Eject" onClick={() => eject()} />
        </div>
      </>
    );

  return <FadeIn>{children}</FadeIn>;
}
