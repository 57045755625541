import React from "react";
import styled from "styled-components";
import { AnchorButton } from "@blueprintjs/core";
import { history } from "../constants";

const Centered = styled.div`
  margin: 5% 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function PageNotFound(props) {
  return (
    <Centered>
      <h1>Page not found</h1>
      <p>You might not have permissions to see this page.</p>
      <AnchorButton
        large
        intent="primary"
        text="Back to home"
        href="/"
        onClick={event => {
          event.preventDefault();
          history.push("/");
        }}
        style={{
          marginTop: "2em"
        }}
      />
    </Centered>
  );
}
