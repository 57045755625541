import React, { useContext } from "react";
import { Tag } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import { workspacesRef, FieldValue } from "../firebase";
import { UserContext } from "../contexts";
import { useParams } from "react-router-dom";
import FadeIn from "../ui/FadeIn";

export default function WorkspaceMembers(props) {
  const { editable = false, style = {} } = props;
  const { workspaceId } = useParams();
  const user = useContext(UserContext);

  const { data, ref } = useSubscription(
    workspaceId && workspacesRef.doc(workspaceId)
  );

  if (!data) return null;

  const { memberUids, memberNames } = data;

  return (
    <div style={style}>
      {memberUids.map(memberUid => (
        <FadeIn
          key={memberUid}
          style={{ display: "inline", fontWeight: "600" }}
        >
          <Tag
            large
            minimal
            style={{ margin: "0.2em" }}
            intent={memberUid === user.uid ? "primary" : null}
            onRemove={
              editable &&
              memberUid !== user.uid &&
              (() => {
                if (window.confirm("Sure you want to remove this person?"))
                  ref.update({
                    memberUids: FieldValue.arrayRemove(memberUid),
                    ["memberNames." + memberUid]: null
                  });
              })
            }
          >
            {memberNames[memberUid]}
          </Tag>
        </FadeIn>
      ))}
    </div>
  );
}
