import { useRef, useEffect, useMemo, useState } from "react";
import shortid from "shortid";
import { workspacesStorageRef } from "./firebase";

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 80%, 98%)`;
}

export function useRandomColor() {
  const { current: color } = useRef(randomColor());
  return color;
}

export function useMp3StorageUrl(sourceId, workspaceId) {
  const mp3storageRef = useMemo(
    () => sourceId && workspacesStorageRef.child(workspaceId).child(sourceId),
    [workspaceId, sourceId]
  );

  const [mp3Url, setMp3Url] = useState(null);

  useEffect(() => {
    if (!mp3storageRef) {
      setMp3Url(null);
      return;
    }
    let mounted = true;
    mp3storageRef.getDownloadURL().then(url => mounted && setMp3Url(url));
    return () => (mounted = false);
  }, [mp3storageRef]);

  return mp3Url;
}

export const baseUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");

export function getUnguessableCode() {
  return shortid.generate() + shortid.generate();
}

export function packMarksIntoLanes(sourceMarksData) {
  const lanes = [];

  Object.keys(sourceMarksData).forEach(markId => {
    const { length, time } = sourceMarksData[markId];
    if (!length) return;

    const end = time + length;

    let availableLane = lanes.find(
      lane =>
        !lane.find(comparedMarkId => {
          const comparedMark = sourceMarksData[comparedMarkId];
          const comparedStart = comparedMark.time;
          const comparedEnd = comparedStart + comparedMark.length;
          const collision =
            (time > comparedStart && time < comparedEnd) ||
            (end > comparedStart && end < comparedEnd) ||
            (time < comparedStart && end > comparedEnd);
          return collision;
        }) && lane
    );

    if (!availableLane) {
      availableLane = [];
      lanes.push(availableLane);
    }

    availableLane.push(markId);
  });

  return lanes;
}

export function usePackedMarkLanes(sourceMarksData) {
  return useMemo(() => sourceMarksData && packMarksIntoLanes(sourceMarksData), [
    sourceMarksData
  ]);
}

export function workspaceUrl(workspaceId) {
  return ["/workspaces", workspaceId].join("/");
}

export function sourceUrl(workspaceId, sourceId) {
  return [workspaceUrl(workspaceId), "sources", sourceId].join("/");
}

export function markUrl(workspaceId, sourceId, markId) {
  if (!markId) return sourceUrl(workspaceId, sourceId);
  return [sourceUrl(workspaceId, sourceId), "marks", markId].join("/");
}
