import React, { useContext } from "react";
import "styled-components/macro";
import { Button } from "@blueprintjs/core";
import formatDuration from "format-duration";
import { useSubscription } from "react-firehook";
import { sourcesRef, workspacesRef } from "../../firebase";
import { history } from "../../constants";
import {
  PlayerContext,
  PlayerTimeContext,
  CurrentlyPlayingMarksContext
} from "../../contexts";
import { markUrl } from "../../utils";
import Helmet from "react-helmet";

export default function CurrentlyPlayingField(props) {
  const { loadedSourceWorkspaceId, loadedSourceId } = useContext(PlayerContext);
  const currentlyPlayingMarks = useContext(CurrentlyPlayingMarksContext);

  const playerTime = useContext(PlayerTimeContext);

  const source = useSubscription(sourcesRef.doc(loadedSourceId));
  const workspace = useSubscription(
    loadedSourceWorkspaceId && workspacesRef.doc(loadedSourceWorkspaceId)
  );

  const hasActiveMarks =
    currentlyPlayingMarks && !!currentlyPlayingMarks.length;

  const grayIfActiveMarks = {
    color: hasActiveMarks ? "gray" : null
  };

  const url = markUrl(
    loadedSourceWorkspaceId,
    loadedSourceId,
    hasActiveMarks && currentlyPlayingMarks[currentlyPlayingMarks.length - 1].id
  );

  return (
    <>
      {source.data && workspace.data && (
        <Helmet>
          <title>
            {(hasActiveMarks
              ? currentlyPlayingMarks[currentlyPlayingMarks.length - 1].name +
                " – "
              : "") +
              source.data.name +
              " – " +
              workspace.data.name +
              " | TagDog"}
          </title>
        </Helmet>
      )}

      <Button
        title={
          hasActiveMarks
            ? "Go to " +
              (currentlyPlayingMarks[0].name || "playing mark") +
              " (" +
              (source.data ? source.data.name : "...") +
              ")"
            : source.data && "Go to timeline for " + source.data.name
        }
        icon="pulse"
        onClick={() => history.push(url)}
        css={`
          font-size: 0.8em !important;
          overflow: hidden;
          max-height: 40px;

          @media (max-width: 1100px) {
            span.bp3-icon {
              margin-right: 0;
            }
          }
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            max-width: 16em;

            @media (max-width: 1100px) {
              display: none;
            }
          `}
        >
          <div
            css={`
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            `}
          >
            <strong css={``}>
              {currentlyPlayingMarks &&
                currentlyPlayingMarks
                  .reverse()
                  .map(mark => mark.name || "Untitled mark")
                  .join(", ")}{" "}
            </strong>
            <span style={grayIfActiveMarks}>
              {hasActiveMarks && source.data && source.data.name && <br />}
              {source.data && source.data.name}
            </span>
          </div>

          <div
            style={{
              margin: "0 0 0 1em",
              display: "flex",
              flexDirection: "column"
            }}
          >
            {hasActiveMarks && (
              <strong>
                {formatDuration(
                  (playerTime - currentlyPlayingMarks[0].time) * 1000
                )}
              </strong>
            )}
            <span style={grayIfActiveMarks}>
              {formatDuration(playerTime * 1000)}
            </span>
          </div>
        </div>
      </Button>
    </>
  );
}
