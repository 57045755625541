import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { configureFirehook } from "react-firehook";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyBkJXDr3b4zCIg1LuHfcPbPyY5fEyUjoUg",
  authDomain: "band-aid-ff041.firebaseapp.com",
  databaseURL: "https://band-aid-ff041.firebaseio.com",
  projectId: "band-aid-ff041",
  storageBucket: "band-aid-ff041.appspot.com",
  messagingSenderId: "839852255160",
  appId: "1:839852255160:web:8da0422765d5b2f8"
});

configureFirehook({
  log: process.env.NODE_ENV === "development"
});

export const provider = new firebase.auth.GoogleAuthProvider();

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = app.functions("europe-west1");
export const TaskEvent = firebase.storage.TaskEvent;

export const workspacesRef = db.collection("workspaces");
export const sourcesRef = db.collection("sources");
export const marksRef = db.collection("marks");
export const profilesRef = db.collection("profiles");
export const chatMessagesRef = db.collection("chatMessages");
export const userSettingsRef = db.collection("userSettings");

export const workspacesStorageRef = storage.ref("/workspaces/");
export const waveformsStorageRef = storage.ref("/waveforms/");
export const peakFilesStorageRef = storage.refFromURL(
  `gs://band-aid-ff041-waveforms`
);

export const FieldValue = firebase.firestore.FieldValue;

const getFunction = (...args) => functions.httpsCallable(...args);

export const getSourceUploadUrl = getFunction("getSourceUploadUrl");

// getSourceUploadUrl({ workspaceId, sourceId: "123" }).then(console.log);
