import React, { useState } from "react";
import { MenuItem, Button } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import {
  stringIncludeIgnoreCase,
  stringMenuItemRenderer,
  createNewItemRenderer
} from "../../ui/helpers/selectHelpers";
import TagList from "./TagList";

export default function TagsInput(props) {
  const { onItemRemove, ...rest } = props;

  const [opened, setOpened] = useState();

  const hasTags = props.selectedItems.length;

  if (!opened) {
    return (
      <div style={{ display: "flex" }}>
        <TagList tags={props.selectedItems} />

        <Button
          large
          minimal
          text="Add tags..."
          icon={hasTags ? null : "tag"}
          style={{
            marginLeft: hasTags ? null : "-1em",
            marginTop: hasTags ? "-0.5em" : null,
            color: "gray"
          }}
          onClick={() => setOpened(true)}
        />
      </div>
    );
  }

  return (
    <MultiSelect
      resetOnSelect
      itemPredicate={stringIncludeIgnoreCase}
      itemRenderer={stringMenuItemRenderer}
      createNewItemFromQuery={query => query}
      createNewItemRenderer={createNewItemRenderer}
      tagInputProps={{
        large: true,
        placeholder: "Tag this...",
        leftIcon: "tag",
        inputProps: {
          autoFocus: opened,
          placeholder: "Add tags...",
          onBlur: () => setOpened(false)
        },
        onRemove: onItemRemove,
        tagProps: {
          minimal: "true"
        }
      }}
      tagRenderer={tag => tag}
      noResults={<MenuItem disabled={true} text="No results" />}
      {...rest}
    />
  );
}
