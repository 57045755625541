import React from "react";
import "styled-components/macro";
import { Tag } from "@blueprintjs/core";

export default function TagList(props) {
  const { tags } = props;
  return (
    <>
      <div>
        {tags.map(tag => (
          <Tag
            key={tag}
            minimal
            icon="tag"
            css={`
              margin: 0 0.5em 0.5em 0;

              .bp3-icon {
                color: gray;
              }
            `}
          >
            {tag}
          </Tag>
        ))}
      </div>
    </>
  );
}
