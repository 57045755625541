import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import "styled-components/macro";
import { Switch } from "@blueprintjs/core";
import { useWindowDimensions } from "@josteinbe/reager";
import { useSubscription } from "react-firehook";
import { Link } from "react-router-dom";
import { PlayerContext, PlayerTimeContext } from "../contexts";
import { marksRef } from "../firebase";
import MarksTableRenderer from "./MarksTableRenderer";
import CenteredSection from "../ui/CenteredSection";
import { HeaderSection } from "../ui/settings/settingsStyles";
import { workspaceUrl } from "../utils";

export default function MarksTable(props) {
  const { goToLocation, loadedSourceId } = useContext(PlayerContext);

  const playerTime = useContext(PlayerTimeContext);

  const { sourceId, workspaceId } = useParams();

  const { width: windowWidth } = useWindowDimensions();

  const showDiscoverer = windowWidth > 900;

  const [orderByRating, setOrderByRating] = useState(false);
  const [editable, setEditable] = useState(false);

  const { ready, data } = useSubscription(
    marksRef
      .where("sourceId", "==", sourceId)
      .where("workspaceId", "==", workspaceId)
      .orderBy("time", "asc")
  );

  const marksMeta_ = {};

  const ratingOrder = useMemo(
    () =>
      data && Object.keys(data).sort((a, b) => data[b].rating - data[a].rating),
    [data]
  );

  if (data) {
    Object.keys(data).forEach(markId => {
      const {
        sourceId: markSourceId,
        time: markTime,
        length: markLength
      } = data[markId];

      marksMeta_[markId] = {
        isPlaying: !!(
          loadedSourceId === markSourceId &&
          markLength &&
          playerTime >= markTime &&
          playerTime < markTime + markLength
        ),
        pastBeginning: playerTime >= markTime
      };
    });
  }
  const marksMetaJson = JSON.stringify(marksMeta_);

  // the reason for all this madness is to make marksMeta comparable with normal equals, so it works with React.memo
  // it is quite ugly, but better that rendering the whole table every time player time updates.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const marksMeta = useMemo(() => marksMeta_, [marksMetaJson]);

  return (
    <CenteredSection>
      {data && !!Object.keys(data).length && (
        <HeaderSection>
          <div>
            <Switch
              large
              inline
              label="Order by rating"
              checked={orderByRating}
              onChange={() => setOrderByRating(v => !v)}
              css={`
                margin-left: 1em;

                @media (max-width: 490px) {
                  display: none !important;
                }
              `}
            />

            <Switch
              large
              inline
              label="Editable table"
              checked={editable}
              onChange={() => setEditable(v => !v)}
              css={`
                @media (max-width: 600px) {
                  display: none !important;
                }
              `}
            />

            <Link to={workspaceUrl(workspaceId) + "/favorites"}>
              View favorites from all sources
            </Link>
          </div>
        </HeaderSection>
      )}

      {ready && (
        <MarksTableRenderer
          showTags={windowWidth > 800}
          order={orderByRating && ratingOrder}
          {...{
            sourceId,
            workspaceId,
            data,
            showDiscoverer,
            goToLocation,
            marksMeta,
            editable
          }}
        />
      )}
    </CenteredSection>
  );
}
