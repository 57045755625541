import React from "react";
import styled from "styled-components";

const StyledAppFooter = styled.footer`
  color: gray;
  max-width: 1200px;
  margin: 2em auto;

  text-align: center;
`;

export default function AppFooter(props) {
  return <StyledAppFooter>&copy; 2020 TagDog</StyledAppFooter>;
}
