import React, { useContext } from "react";
import {
  CurrentlyPlayingMarksContext,
  PlayerContext,
  PlayerTimeContext
} from "../../contexts";
import ProgressField from "../../ui/ProgressField";

export default function CurrentlyPlayingMarkProgress(props) {
  const currentlyPlayingMarks = useContext(CurrentlyPlayingMarksContext);

  const { goToLocation } = useContext(PlayerContext);
  const playerTime = useContext(PlayerTimeContext);

  if (!currentlyPlayingMarks || !currentlyPlayingMarks.length) return null;

  const {
    name,
    time,
    length,
    sourceId: markSourceId,
    workspaceId: markWorkspaceId
  } = currentlyPlayingMarks[0];

  const markIsPlaying = playerTime >= time && playerTime < time + length;

  return (
    <>
      <ProgressField
        noLabels
        innerText={name}
        onClick={markRelativeTime =>
          goToLocation(
            markWorkspaceId,
            markSourceId,
            time + markRelativeTime,
            true
          )
        }
        length={length}
        time={playerTime - time}
        height="30px"
        active={markIsPlaying}
      />
    </>
  );
}
