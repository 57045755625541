import React, { useContext } from "react";
import "styled-components/macro";
import { Route, Switch, useParams } from "react-router-dom";
import { Button } from "@blueprintjs/core";
import NavItems, { ItemNavLink } from "./NavItems";
import WorkspaceMembers from "../../workspace/WorkspaceMembers";
import { useWindowDimensions } from "@josteinbe/reager";
import { history } from "../../constants";
import { UserContext, UserProfileContext } from "../../contexts";
import { workspaceUrl, sourceUrl } from "../../utils";
import { createWorkspace } from "../../actions";

function ClickableMembers(props) {
  const { workspaceId } = useParams();
  const { width: windowWidth } = useWindowDimensions();

  return (
    <>
      {windowWidth > 700 && (
        <div
          style={{ cursor: "pointer" }}
          title="Manage members"
          onClick={() => history.push(workspaceUrl(workspaceId) + "/settings")}
        >
          <WorkspaceMembers style={{ margin: "1em 0" }} />
        </div>
      )}
    </>
  );
}

export default function NavbarBottom(props) {
  const { uid } = useContext(UserContext);
  const { displayName } = useContext(UserProfileContext);

  return (
    <NavItems>
      <Route
        path={["/workspaces/:workspaceId/sources/:sourceId"]}
        render={({
          match: {
            params: { workspaceId, sourceId }
          }
        }) => (
          <ItemNavLink to={sourceUrl(workspaceId, sourceId)}>
            Timeline
          </ItemNavLink>
        )}
      />

      <Switch>
        <Route
          exact
          path={["/workspaces/:workspaceId/sources/:sourceId/marks/:markId"]}
        />
        <Route
          exact
          path={["/workspaces/:workspaceId/sources/:sourceId/(settings)?"]}
          render={({
            match: {
              params: { workspaceId, sourceId }
            }
          }) => (
            <ItemNavLink to={sourceUrl(workspaceId, sourceId) + "/settings"}>
              Edit metadata
            </ItemNavLink>
          )}
        />
        <Route
          exact
          path={["/workspaces/:workspaceId/(favorites|settings)?"]}
          render={({
            match: {
              params: { workspaceId }
            }
          }) => (
            <>
              <ItemNavLink to={workspaceUrl(workspaceId)}>Sources</ItemNavLink>
              <ItemNavLink to={workspaceUrl(workspaceId) + "/favorites"}>
                Favorites
              </ItemNavLink>
              <ItemNavLink to={workspaceUrl(workspaceId) + "/settings"}>
                Workspace settings
              </ItemNavLink>
            </>
          )}
        />
        <Route path={["/(account)?"]} exact>
          <ItemNavLink to="/" text="Workspaces" />
          <ItemNavLink to="/account" text="Account" />
        </Route>
      </Switch>

      <div style={{ flexGrow: 1 }}></div>

      <Switch>
        <Route path={["/workspaces/:workspaceId"]}>
          <ClickableMembers />
        </Route>
        <Route>
          <Button
            large
            intent="primary"
            icon="plus"
            text="New workspace"
            style={{ marginTop: "1em" }}
            onClick={async () => {
              const doc = await createWorkspace(uid, displayName);
              history.push("/workspaces/" + doc.id);
            }}
          />
        </Route>
      </Switch>
    </NavItems>
  );
}
