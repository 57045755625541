import React from "react";
import "styled-components/macro";
import { sourcesRef, FieldValue, marksRef } from "../firebase";
import { Icon } from "@blueprintjs/core";

export default function MarkStarRating(props) {
  const {
    editable,
    markId,
    iconSize,
    markData: { name, rating, sourceId }
  } = props;

  return (
    <span>
      {[...Array(5).keys()]
        .map(n => n + 1)
        .map(n => (
          <Icon
            key={n}
            iconSize={iconSize}
            style={{ color: "gold", cursor: editable ? "pointer" : null }}
            icon={n <= rating ? "star" : "star-empty"}
            title={editable && "Change rating"}
            onClick={event => {
              if (!editable) return;
              event.stopPropagation();
              const newRating = n === rating ? 0 : n;
              marksRef.doc(markId).update({
                rating: newRating
              });
              sourcesRef.doc(sourceId).update({
                ["marksByRating." + rating]: FieldValue.arrayRemove(markId),
                ["marksByRating." + newRating]: FieldValue.arrayUnion(markId),
                ["markNames." + markId]: newRating > 3 ? name : null
              });
            }}
          />
        ))}
    </span>
  );
}
