import styled from "styled-components";
import { NavLink } from "react-router-dom";

const NavItems = styled.div`
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1.2em 0;
  font-size: 16px;
`;

export default NavItems;

export const ItemNavLink = styled(NavLink).attrs(props => ({
  exact: true,
  children: props.children || props.text
}))`
  margin: 1.2em 1.5em 0 0;
  transition: background-color, color 0.3s ease;

  &:hover,
  &:active,
  &.active,
  &.active:link,
  &.active:visited {
    /* // color: #eee; */
    color: black;
    text-decoration: none;
  }
`;

export const CrumbNavLink = styled(ItemNavLink)`
  margin: 0;
  font-size: 1.5em !important;
  font-weight: 600;
  vertical-align: baseline;
  /* cursor: pointer; */

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
