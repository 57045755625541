import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef
} from "react";
import "styled-components/macro";
import { useSubscription } from "react-firehook";
import { useQueryString } from "@josteinbe/reager";
import { useMp3StorageUrl } from "../../utils";
import { marksRef } from "../../firebase";
import {
  PlayerContext,
  PlayerVolumeContext,
  PlayerTimeContext,
  CurrentlyPlayingMarksContext
} from "../../contexts";
import FadeIn from "../../ui/FadeIn";
import PlayerControls from "./PlayerControls";

const storedVolumeJson = localStorage.getItem("player-volume");
const storedVolume = storedVolumeJson ? JSON.parse(storedVolumeJson) : 1;

let waitingRequest = null;

export default function PlayerProvider(props) {
  const { children } = props;
  const query = useQueryString();
  const { t } = query;

  const [loadedSourceMeta, setLoadedSourceMeta] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [volume, setVolume] = useState(storedVolume);
  const audioRef = useRef();

  const { id: loadedSourceId, workspaceId } = loadedSourceMeta || {};

  const mp3Url = useMp3StorageUrl(loadedSourceId, workspaceId);

  // Refresh ui on navigation:
  useEffect(() => setPlaying(false), [setPlaying, loadedSourceMeta]);
  useEffect(() => setTime(t || 0), [setTime, t]);

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.volume = volume;
    localStorage.setItem("player-volume", JSON.stringify(volume));
  }, [volume, audioRef]);

  const goToLocation = useCallback(
    (locWorkspaceId, locSourceId, t, play) => {
      if (!locWorkspaceId)
        throw new Error("Missing workspace id in goToLocation");
      if (!locSourceId) throw new Error("Missing source id id in goToLocation");

      if (
        loadedSourceMeta &&
        locSourceId === loadedSourceMeta.id &&
        audioRef.current
      ) {
        if (typeof t === "number") audioRef.current.currentTime = t;
        if (play && !playing) audioRef.current.play();
      } else {
        setLoadedSourceMeta({ id: locSourceId, workspaceId: locWorkspaceId });
        setTime(t);
        waitingRequest = {
          sourceId: locSourceId,
          time: t,
          play
        };
      }
    },
    [loadedSourceMeta, playing]
  );

  // callbacks inside heavy tables can use this to avoid re-rendering
  const getTime = useCallback(() => audioRef.current.currentTime, [audioRef]);

  const eject = useCallback(() => setLoadedSourceMeta(null), []);

  const marks = useSubscription(
    workspaceId &&
      loadedSourceId &&
      marksRef
        .where("workspaceId", "==", workspaceId)
        .where("sourceId", "==", loadedSourceId)
        .orderBy("time", "asc")
  );

  const currentlyPlayingMarks = useMemo(
    () =>
      marks.data &&
      Object.keys(marks.data)
        .map(markId => ({ ...marks.data[markId], id: markId }))
        .filter(
          ({ time: markTime, length }) =>
            time >= markTime && time < markTime + length
        ),
    [marks.data, time]
  );

  let lastStartedMark = null;

  marks.data &&
    Object.keys(marks.data).find(markId => {
      const mark = marks.data[markId];
      if (time > mark.time) {
        lastStartedMark = { ...mark, id: markId };
        return false;
      }
      return !!lastStartedMark;
    });

  return (
    <PlayerContext.Provider
      value={{
        mp3Url,
        audioRef,
        playing,
        setTime,
        setVolume,
        loadedSourceId,
        loadedSourceWorkspaceId: workspaceId,
        eject,
        goToLocation,
        getTime,
        lastStartedMark
      }}
    >
      <CurrentlyPlayingMarksContext.Provider value={currentlyPlayingMarks}>
        <PlayerTimeContext.Provider value={time}>
          <PlayerVolumeContext.Provider value={volume}>
            <div
              css={`
                margin-bottom: 8em;
              `}
            >
              {mp3Url && (
                <audio
                  src={mp3Url}
                  ref={audioRef}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                  onEnded={() => setPlaying(false)}
                  onCanPlay={event => {
                    event.target.volume = volume;
                    if (
                      waitingRequest &&
                      waitingRequest.sourceId === loadedSourceId
                    ) {
                      if (waitingRequest.time)
                        audioRef.current.currentTime = waitingRequest.time;
                      if (waitingRequest.play) audioRef.current.play();
                      waitingRequest = null;
                    }
                  }}
                  onTimeUpdate={event =>
                    !waitingRequest && setTime(event.target.currentTime)
                  }
                />
              )}
              <FadeIn>{children}</FadeIn>
              <PlayerControls />
            </div>
          </PlayerVolumeContext.Provider>
        </PlayerTimeContext.Provider>
      </CurrentlyPlayingMarksContext.Provider>
    </PlayerContext.Provider>
  );
}
