import React from "react";
import "styled-components/macro";

function CloudIllustration() {
  return (
    <span
      role="img"
      aria-label=""
      css={`
        font-size: 4em;
        opacity: 0.6;
        margin-bottom: 0px;
        @media (max-width: 850px) {
          font-size: 2em;
        }
      `}
    >
      ☁️
    </span>
  );
}

function FileDropTargetCard(props) {
  const { children, rootProps, active } = props;

  return (
    <div
      {...rootProps}
      css={`
        flex: 1;
        font-size: 1.4em;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 10px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        // color: #999;
        outline: none;
        transition: all 0.24s ease-in-out;
      `}
      style={{
        borderColor: active ? "blue" : "#eee",
        opacity: active ? 1 : 0.5,
        cursor: !active && "pointer"
      }}
    >
      {children}
    </div>
  );
}

export default function SourceUploader(props) {
  const {
    uploaderProps: { getRootProps, getInputProps, isDragActive, multiple }
  } = props;

  return (
    <FileDropTargetCard rootProps={getRootProps()} active={isDragActive}>
      <input {...getInputProps()} />

      <CloudIllustration />

      <p>
        {multiple
          ? "Drop files here or click to upload..."
          : "Drop a file here (or click) to replace audio..."}
      </p>
    </FileDropTargetCard>
  );
}
