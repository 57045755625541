import React from "react";
import { useCallback, useContext } from "react";
import {
  TaskEvent,
  workspacesStorageRef,
  waveformsStorageRef,
  FieldValue,
  db,
  workspacesRef,
  sourcesRef
} from "./firebase";
import { ProgressBar } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import "styled-components/macro";
import { toast } from "./constants";
import { UserContext, UserProfileContext } from "./contexts";

export async function createWorkspace(uid, displayName) {
  const doc = workspacesRef.doc();

  await doc.set({
    name: "",
    memberUids: [uid],
    memberNames: { [uid]: displayName },
    deleted: false,
    hue: Math.floor(Math.random() * 360),
    sourcesCount: 0,
    marksCount: 0,
    tagUsageCounts: {}
  });

  return doc;
}

export function renameMark(markRef, sourceId, rating, name) {
  if (!name) return;

  markRef.update({ name });

  if (rating > 3)
    sourcesRef.doc(sourceId).update({
      ["markNames." + markRef.id]: name
    });
}

function createProgressToast(initialParams, renderMessage) {
  let key;
  let params = { ...initialParams };

  return newParams => {
    params = { ...params, ...newParams };
    const { success, error, progress, icon } = params;

    key = toast(
      {
        timeout: error || success ? 2000 : 0,
        icon,
        intent: (error && "danger") || (success && "success") || null,
        message: (
          <>
            {!success && !error && typeof progress === "number" && (
              <ProgressBar
                // key={progress} // refresh bug fix
                intent="primary"
                stripes={!success && !error}
                value={progress}
              />
            )}
            {renderMessage(params)}
          </>
        )
      },
      key
    );
  };
}

function uploadToFirebaseStorage(storageFileRef, file, updateToast) {
  if (!updateToast)
    updateToast = createProgressToast(
      {
        name: file.name,
        icon: "cloud-upload"
      },
      ({ success, error, name }) => (
        <div>
          {name}
          {!!success && <> uploaded!</>}
          {!!error && <>: Upload failed</>}
        </div>
      )
    );

  return new Promise((resolve, reject) => {
    storageFileRef.put(file).on(
      TaskEvent.STATE_CHANGED,
      ({ bytesTransferred, totalBytes }) => {
        updateToast({ progress: bytesTransferred / totalBytes });
      },
      error => {
        updateToast({ error });
        reject(error);
      },
      () => {
        updateToast({ success: true });
        resolve();
      }
    );
  });
}

let activeUpload = Promise.resolve();
let pendingUploadsCount = 0;
let pendingUploadsToastKey;

function updateQueueToast() {
  if (!pendingUploadsCount && !pendingUploadsToastKey) return;
  pendingUploadsToastKey = toast(
    {
      timeout: pendingUploadsCount > 0 ? 0 : 1,
      icon: "cloud-upload",
      message: <div>{pendingUploadsCount || "No"} uploads queued.</div>
    },
    pendingUploadsToastKey
  );
}

async function enqueueUpload(trigger) {
  pendingUploadsCount++;
  activeUpload = activeUpload.then(async () => {
    pendingUploadsCount--;
    updateQueueToast();
    await trigger();
    updateQueueToast();
  });
}

const outputSampleRate = 21;

function createWaveformProgressToast(filename) {
  return createProgressToast(
    {
      name: filename,
      icon: "pulse"
    },
    ({ success, error, name, message }) => {
      if (success) return <div>Waveform uploaded!</div>;
      if (error) return <div>Waveform generation failed!</div>;
      return <div>{message}</div>;
    }
  );
}

async function generateWaveformData(file, workspaceId, sourceId, updateToast) {
  const audioContext = new AudioContext();

  const reader = new FileReader();

  if (!updateToast) updateToast = createWaveformProgressToast(file.name);

  return await new Promise((resolve, reject) => {
    reader.addEventListener("load", async event => {
      const arrayBuffer = event.target.result;

      updateToast({ message: "Decoding audio..." });
      const decoded = await audioContext.decodeAudioData(arrayBuffer);
      const channel = decoded.getChannelData(0);

      const inputSamplesPerOutputSample = decoded.sampleRate / outputSampleRate;

      updateToast({ message: "Generating peak data..." });

      const totalOutputSamples = Math.floor(
        decoded.length / inputSamplesPerOutputSample
      );

      const output = new Uint8ClampedArray(totalOutputSamples);

      await new Promise((resolve, reject) => {
        let i = 0;
        const step = () => {
          if (i === totalOutputSamples) {
            updateToast({ progress: 1 });
            resolve();
            return;
          }

          updateToast({
            progress: i / totalOutputSamples
          });

          for (
            const batchEnd = Math.min(i + 1024, totalOutputSamples);
            i < batchEnd;
            i++
          ) {
            let max = 0;
            const areaStart = i * inputSamplesPerOutputSample;
            const areaEnd = areaStart + inputSamplesPerOutputSample;
            for (let j = areaStart; j < areaEnd; j++) {
              if (channel[j] > max) max = channel[j];
            }

            output.set([max * 128], i);
          }

          setTimeout(step);
        };

        setTimeout(step);
      });

      updateToast({ message: "Uploading waveform..." });

      const filename = sourceId + ".peaks";
      const peaksStorageRef = waveformsStorageRef.child(filename);
      const peaksFile = new File([output], filename);

      await uploadToFirebaseStorage(peaksStorageRef, peaksFile, updateToast);
      resolve(decoded.length / decoded.sampleRate);
    });

    updateToast({ message: "Loading file..." });
    reader.readAsArrayBuffer(file);
  });
}

export async function generateWaveformForExistingSource(
  workspaceId,
  sourceId,
  sourceName
) {
  const updateToast = createWaveformProgressToast(sourceName);

  updateToast({ message: "Downloading audio..." });
  const mp3storageRef = workspacesStorageRef.child(workspaceId).child(sourceId);
  const audioUrl = await mp3storageRef.getDownloadURL();
  const response = await fetch(audioUrl);
  const mp3Buffer = await response.arrayBuffer();
  const mp3File = new File([mp3Buffer], sourceName);

  await generateWaveformData(mp3File, workspaceId, sourceId, updateToast);
}

export function useSourceUploader(opts) {
  const { existingSourceId, onSourceUploaded } = opts;
  const multiple = !existingSourceId;
  const { workspaceId } = useParams();
  const { uid } = useContext(UserContext);
  const { displayName } = useContext(UserProfileContext);

  const onDropAccepted = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const sourceRef = existingSourceId
          ? sourcesRef.doc(existingSourceId)
          : sourcesRef.doc();

        const audioStorageRef = workspacesStorageRef
          .child(workspaceId)
          .child(sourceRef.id);

        enqueueUpload(async () => {
          const length = (
            await Promise.all([
              uploadToFirebaseStorage(audioStorageRef, file),
              generateWaveformData(file, workspaceId, sourceRef.id)
            ])
          )[1];

          const sharedFields = {
            uploadedByUid: uid,
            uploadedByName: displayName,
            filename: file.name,
            type: file.type,
            size: file.size,
            length
          };

          if (existingSourceId) {
            await sourceRef.update({
              ...sharedFields,
              "timestamps.replaced": new Date()
            });
          } else {
            const batch = db.batch();

            batch.set(sourceRef, {
              ...sharedFields,
              workspaceId,
              name: file.name.replace(/\.[^/.]+$/, ""), // trim extension
              timestamp: new Date(),
              hue: Math.floor(Math.random() * 360),
              marksCount: 0,
              chatsCount: 0
            });

            batch.update(workspacesRef.doc(workspaceId), {
              sourcesCount: FieldValue.increment(1)
            });

            await batch.commit();
          }

          onSourceUploaded && onSourceUploaded();
        });
      });
    },

    [uid, displayName, workspaceId, existingSourceId, onSourceUploaded]
  );

  const dropzoneValues = useDropzone({
    onDropAccepted,
    multiple,
    accept: ".mp3,.aac,.mp4,.m4a",
    maxSize: 500 * 1024 * 1024,
    onDropRejected: () =>
      toast({
        message: "That file is not allowed, sorry.",
        intent: "danger"
      })
  });

  return { ...dropzoneValues, multiple };
}
