import React from "react";
import { MenuItem } from "@blueprintjs/core";

export function stringIncludeIgnoreCase(query, item) {
  if (!query.length) return true;
  const lcq = query.toLowerCase();
  return item.toLowerCase().includes(lcq);
}

export function stringMenuItemRenderer(
  item,
  { handleClick, modifiers, query }
) {
  if (!modifiers.matchesPredicate) return null;

  return (
    <MenuItem
      key={item}
      icon="tag"
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      text={item}
    />
  );
}

export function createNewItemRenderer(query, active, handleClick) {
  return (
    <MenuItem
      icon="clean"
      active={active}
      onClick={handleClick}
      text={query}
      labelElement="New tag"
    />
  );
}
