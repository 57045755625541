import React from "react";
import "styled-components/macro";
import NavbarTop from "./NavbarTop";
import { useParams } from "react-router-dom";
import { useSubscription } from "react-firehook";
import { workspacesRef, sourcesRef } from "../../firebase";
import NavbarBottom from "./NavbarBottom";

function NavbarContainer(props) {
  const { children } = props;
  const { workspaceId, sourceId } = useParams();

  const workspace = useSubscription(
    workspaceId && workspacesRef.doc(workspaceId)
  );

  const source = useSubscription(sourceId && sourcesRef.doc(sourceId));

  const data =
    source.data && source.data.backgroundImage ? source.data : workspace.data;

  const backgroundImage = data && data.backgroundImage;

  return (
    <>
      <div
        css={`
          background-color: #68a6d9;
        `}
      >
        <div
          css={`
            height: 250px;
            /* max-width: 1200px; */

            /* opacity breaks layout in chrome */
            /* opacity: 0; */
            transition: background-position 0.5s ease;
            background-position: 50% calc(50% - 10px);
            /* background-repeat: no-repeat; */

            ${backgroundImage && backgroundImage.url
              ? `background: url("${backgroundImage.url}") center no-repeat; opacity: 1;`
              : ""}
            background-size: cover;
            /* background-attachment: fixed; */
          `}
        >
          &nbsp;
        </div>
      </div>
      <nav
        css={`
          margin: -150px auto 0;
          padding: 2em 2em 1em;
          max-width: 1200px;
          background-color: #fdfdfd;
          /* background-color: rgba(255, 255, 255, 1); */
          /* background-color: rgba(20, 20, 20, 0.98); */
          border-radius: 4px;

          @media (max-width: 1200px) {
            padding: 1em;
          }
        `}
      >
        {children}
      </nav>
    </>
  );
}

export default function Navbar(props) {
  return (
    <NavbarContainer>
      <div
        css={`
          a,
          &.bp3-dark a {
            color: gray;
          }
        `}
      >
        <NavbarTop />
        <NavbarBottom />
      </div>
    </NavbarContainer>
  );
}
