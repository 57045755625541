import React, { useMemo, useContext } from "react";
import "styled-components/macro";
import { useParams } from "react-router";
import MarksTableRenderer from "../source/MarksTableRenderer";
import { useQueryString, useWindowDimensions } from "@josteinbe/reager";
import { useSubscription } from "react-firehook";
import { history } from "../constants";
import { marksRef, workspacesRef } from "../firebase";
import { PlayerTimeContext, PlayerContext } from "../contexts";
import CenteredSection from "../ui/CenteredSection";
import { HeaderSection } from "../ui/settings/settingsStyles";
import { Header } from "../ui/headers";
import FadeIn from "../ui/FadeIn";
import TagFilterPicker from "../shared/tags/TagFilterPicker";

export default function Favorites() {
  const { workspaceId } = useParams();

  const { goToLocation, loadedSourceId, getTime } = useContext(PlayerContext);

  const playerTime = useContext(PlayerTimeContext);

  const { width: windowWidth } = useWindowDimensions();

  const { tagFilter: tagFilterEncoded } = useQueryString();
  const tagFilter = tagFilterEncoded && decodeURIComponent(tagFilterEncoded);

  const setTagFilter = tagFilter =>
    history.replace("?tagFilter=" + encodeURIComponent(tagFilter));

  const workspace = useSubscription(workspacesRef.doc(workspaceId));
  const marks = useSubscription(
    tagFilter
      ? marksRef
          .where("workspaceId", "==", workspaceId)
          .where("tags", "array-contains", tagFilter)
          .orderBy("rating", "desc")
          .limit(120)
      : marksRef
          .where("workspaceId", "==", workspaceId)
          .orderBy("rating", "desc")
          .limit(120)
  );

  const marksMeta_ = {};

  const marksCount = workspace.data && workspace.data.marksCount;
  const tagUsageCounts = workspace.data && workspace.data.tagUsageCounts;

  if (marks.data) {
    Object.keys(marks.data).forEach(markId => {
      const {
        sourceId: markSourceId,
        time: markTime,
        length: markLength
      } = marks.data[markId];

      marksMeta_[markId] = {
        isPlaying: !!(
          loadedSourceId === markSourceId &&
          markLength &&
          playerTime >= markTime &&
          playerTime < markTime + markLength
        ),
        pastBeginning: playerTime >= markTime
      };
    });
  }
  const marksMetaJson = JSON.stringify(marksMeta_);

  // the reason for all this madness is to make marksMeta comparable with normal equals, so it works with React.memo
  // it is quite ugly, but better that rendering the whole table every time player time updates.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const marksMeta = useMemo(() => marksMeta_, [marksMetaJson]);

  const usedTags =
    tagUsageCounts &&
    Object.keys(tagUsageCounts).filter(tag => tagUsageCounts[tag]);

  return (
    <FadeIn>
      <CenteredSection
        css={`
          background-color: #fdfdfd;
          border-radius: 4px;
        `}
      >
        <HeaderSection>
          <Header>Favorite marks</Header>

          <div
            css={`
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap-reverse;
              margin: 2em 0 0;
            `}
          >
            {marks.data && (
              <FadeIn
                css={`
                  margin: 1em 1em 0 0;
                `}
              >
                Showing {Object.keys(marks.data).length} of {marksCount} total.
              </FadeIn>
            )}

            <div
              css={`
                flex-grow: 1;
              `}
            />

            {(usedTags || tagFilter) && (
              <TagFilterPicker
                items={usedTags || []}
                onItemSelect={setTagFilter}
                selectedItem={tagFilter}
              />
            )}
          </div>
        </HeaderSection>

        {marks.ready && (
          <div style={{ marginTop: "5em" }}>
            <MarksTableRenderer
              acrossSources
              showTags={windowWidth > 800}
              data={marks.data}
              {...{
                goToLocation,
                marksMeta,
                getTime,
                workspaceId
              }}
            />
          </div>
        )}
      </CenteredSection>
    </FadeIn>
  );
}
