import React, { memo, useContext } from "react";
import "styled-components/macro";
import { indexToColor } from "@josteinbe/reager";
import { useParams } from "react-router-dom";
import { useSubscription } from "react-firehook";
import { sourcesRef, marksRef } from "../firebase";
import FadeIn from "../ui/FadeIn";
import { history } from "../constants";
import { usePackedMarkLanes, markUrl } from "../utils";
import { Icon } from "@blueprintjs/core";
import { PlayerContext } from "../contexts";

function MarksVizRenderer_(props) {
  const { compact, sourceMarksData, sourceData } = props;
  const lanes = usePackedMarkLanes(sourceMarksData);

  const { length } = sourceData;
  if (!length) return null;

  return (
    <FadeIn>
      {lanes.map(
        (lane, i) =>
          (!compact || i < 1) && (
            <div key={i} style={{ display: "flex" }}>
              {lane.map((markId, i) => {
                const markIndex = Object.keys(sourceMarksData).indexOf(markId);

                const {
                  time,
                  length: markLength,
                  name,
                  workspaceId,
                  sourceId,
                  chatsCount
                } = sourceMarksData[markId];

                const previousMark = sourceMarksData[i && lane[i - 1]];
                const nextMark = sourceMarksData[lane[i + 1]];
                const previousEnd = previousMark
                  ? previousMark.time + previousMark.length
                  : 0;

                return (
                  <React.Fragment key={markIndex}>
                    {time && (
                      <Segment
                        // the white segment that separates segments (time - previousEnd)
                        length={(time - previousEnd) / length}
                        compact={compact}
                      />
                    )}

                    <Segment
                      color={indexToColor(markIndex)}
                      length={markLength / length}
                      title={name}
                      chatsCount={chatsCount}
                      compact={compact}
                      onClick={() =>
                        history.push(markUrl(workspaceId, sourceId, markId))
                      }
                    />

                    {!nextMark && (
                      <Segment
                        length={(length - (time + markLength)) / length}
                        compact={compact}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )
      )}
    </FadeIn>
  );
}

export function WaveformMarkLabels(props) {
  const { sourceId, workspaceId, markId: selectedMarkId } = useParams();

  const { goToLocation } = useContext(PlayerContext);

  const source = useSubscription(sourcesRef.doc(sourceId));

  const marks = useSubscription(
    sourceId &&
      marksRef
        .where("sourceId", "==", sourceId)
        .where("workspaceId", "==", workspaceId)
        .orderBy("time")
  );

  if (!source.ready || !marks.ready) return null;

  const { length: sourceLength } = source.data;

  return (
    <div style={{ display: "flex" }}>
      {Object.keys(marks.data).map((markId, i) => {
        const mark = marks.data[markId];
        const { time, name, rating } = mark;
        const previousMark = i && Object.values(marks.data)[i - 1];
        const nextMark = Object.values(marks.data)[i + 1];
        const nextThing = nextMark ? nextMark.time : sourceLength;

        return (
          <React.Fragment key={i}>
            {!previousMark && time && <Segment length={time / sourceLength} />}
            <Segment
              text={
                name || (
                  <span style={{ color: "#999" }}>
                    Unnamed mark{" "}
                    <span role="img" aria-label="dog face">
                      🐶
                    </span>
                  </span>
                )
              }
              rating={rating}
              active={markId === selectedMarkId}
              length={(nextThing - time) / sourceLength}
              onClick={
                markId === selectedMarkId
                  ? null
                  : () =>
                      history.push(
                        markUrl(mark.workspaceId, mark.sourceId, markId)
                      )
              }
              onPlayClick={() =>
                goToLocation(workspaceId, sourceId, time, true)
              }
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

const MarksVizRenderer = memo(MarksVizRenderer_);

export default function MarksViz(props) {
  const { compact, sourceId, workspaceId } = props;

  const marks = useSubscription(
    sourceId &&
      marksRef
        .where("sourceId", "==", sourceId)
        .where("workspaceId", "==", workspaceId)
        .orderBy("time")
  );

  const source = useSubscription(sourceId && sourcesRef.doc(sourceId));

  if (!marks.ready || !source.data) return null;
  if (!Object.keys(marks.data).length) return null;

  return (
    <MarksVizRenderer
      {...{
        compact,
        sourceMarksData: marks.data,
        sourceData: source.data
      }}
    />
  );
}

function Segment(props) {
  const {
    length,
    color = "transparent",
    text,
    onClick,
    onPlayClick,
    title,
    chatsCount,
    compact,
    active,
    rating
  } = props;

  const styledText = text && (
    <div
      css={`
        .buttons {
          /* display: flex; */
        }

        .buttons > span {
          display: none;
          pointer-events: auto;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          z-index: 2000;
          background-color: white;
          border: 1px solid #f7f7f7;
          margin: 1em 0.3em 0 0;
          padding: 1em;
          cursor: pointer;
        }

        &:hover {
          pointer-events: auto;
          position: absolute;

          .buttons > span {
            display: block;

            &:hover {
              background-color: #eef;
            }
          }
        }
      `}
    >
      <div
        onClick={onClick}
        style={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          marginTop: "1em",
          fontSize: "0.9em",
          maxHeight: "12em",
          fontWeight: 600,
          color: active ? "#eee" : null,
          padding: "4px 4px",
          borderRadius: "4px",
          position: active ? "relative" : null,
          cursor: onClick && "pointer",
          pointerEvents: "auto",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)"
        }}
        css={`
          background-color: ${active ? "#333" : "#fffffa"};

          &:hover {
            background-color: #eef7ff;
          }
        `}
        title={"Edit mark"}
      >
        {text}{" "}
        {rating >= 4 && (
          <Icon
            icon="star"
            style={{ color: rating === 5 ? "gold" : "silver" }}
          />
        )}
      </div>
      <div className="buttons">
        {onPlayClick && (
          <span onClick={onPlayClick} title="Play mark">
            <Icon icon="play" />
          </span>
        )}
        <span onClick={onClick} title="Edit mark">
          <Icon icon="edit" />
        </span>
      </div>
    </div>
  );

  return (
    <div
      onClick={text ? null : onClick}
      title={title}
      style={{
        cursor: onClick && !text && "pointer",
        height: !text && (compact ? "1px" : "3px"),
        border: !text && "2px solid " + color,
        borderRadius: !text && "2px",
        width: length * 100 + "%"
        // overflow: "hidden"
      }}
    >
      {styledText}
      {!!chatsCount && (
        <div
          style={{ position: "absolute", marginTop: "-1.7em", opacity: 0.3 }}
        >
          <Icon icon="comment" /> {chatsCount}
        </div>
      )}
    </div>
  );
}
