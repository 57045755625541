import styled from "styled-components";
import { Card } from "@blueprintjs/core";

export const SettingsCard = styled(Card).attrs({ elevation: 1 })`
  margin: 0 0 2em;
`;

export const MenuSection = styled(Card)`
  min-width: 16em;
  margin-right: 2em;
  // margin-left: -0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 2em;
  flex-grow: 1;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const MainSection = styled.section`
  flex-grow: 1;
`;

export const HeaderSection = styled.section`
  margin: 0 0 1em;
`;

export const MainPane = styled.div`
  margin: 5em 0 1em;
  display: flex;
  // flex-wrap: wrap;
  align-items: flex-start;
`;
