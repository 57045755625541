import React, { useContext, useState } from "react";
import "styled-components/macro";
import { Card, Button } from "@blueprintjs/core";
import { useParams } from "react-router";
import { useSubscription } from "react-firehook";
import { sourcesRef, workspacesRef } from "../firebase";
import SourceUploader from "./SourceUploader";
import FadeIn from "../ui/FadeIn";
import { PlayerContext } from "../contexts";
import { useSourceUploader } from "../actions";
import SourceFilter from "./SourceFilter";
import SourceCard from "../source/SourceCard";

function SourcesTutorial() {
  const { workspaceId } = useParams();
  const workspace = useSubscription(workspacesRef.doc(workspaceId));

  if (!workspace.ready) return null;

  const { members, dismissals = {} } = workspace.data;

  if (dismissals.addMembers || (members && members.length)) return null;

  return (
    <div
      css={`
        text-align: center;
        margin-bottom: 4em;
        font-weight: 600;
        font-size: 18px;
      `}
    >
      <p>
        You are the only one here. You can invite more members from the settings
        page.
      </p>
      <Button
        large
        onClick={() => {
          // history.push(url + "/settings");
          workspace.ref.update({ "dismissals.addMembers": true });
        }}
      >
        Understood
      </Button>
    </div>
  );
}

function SourceCards(props) {
  const { sourceFilterState } = props;
  const { workspaceId } = useParams();
  const { loadedSourceId } = useContext(PlayerContext);

  const filters = sourceFilterState.filters;

  let query = sourcesRef
    .where("workspaceId", "==", workspaceId)
    .orderBy("timestamp", "desc");

  if (filters.newerThan) {
    query = query.where("timestamp", ">", filters.newerThan);
  }

  if (filters.olderThan) {
    query = query.where("timestamp", "<", filters.olderThan);
  }

  const sources = useSubscription(query);

  const uploaderProps = useSourceUploader({});

  if (!sources.ready) return null;

  let filteredSourceKeys = Object.keys(sources.data);

  if (filters.name)
    filteredSourceKeys = filteredSourceKeys.filter(sourceId => {
      const source = sources.data[sourceId];
      return (source.name || "")
        .toLowerCase()
        .includes(filters.name.toLowerCase());
    });

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    >
      <Card
        interactive
        elevation={2}
        style={{
          width: "25em",
          margin: "1em",
          backgroundColor: "hsl(0, 0%, 99%)",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <SourceUploader uploaderProps={uploaderProps} />
      </Card>
      {filteredSourceKeys.map((sourceId, i) => {
        return (
          <SourceCard
            interactive
            key={sourceId}
            sourceId={sourceId}
            sourceData={sources.data[sourceId]}
            emphasized={sourceId === loadedSourceId}
            fadeDelay={i * 0.01}
          />
        );
      })}
    </div>
  );
}

function useSourceFilterState() {
  const [name, setName] = useState("");
  const [newerThan, setNewerThan] = useState(null);
  const [olderThan, setOlderThan] = useState(null);

  return {
    filters: { name, newerThan, olderThan },
    setName,
    setNewerThan,
    setOlderThan
  };
}

export default function WorkspaceSources(props) {
  const sourceFilterState = useSourceFilterState();

  return (
    <FadeIn
      css={`
        max-width: 1200px;
        padding: 0 1em;
        margin: 0 auto;
      `}
    >
      <SourcesTutorial />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "1em"
        }}
      >
        <SourceFilter {...sourceFilterState} />
      </div>
      <SourceCards sourceFilterState={sourceFilterState} />
    </FadeIn>
  );
}
