import { useSubscription } from "react-firehook";
import { marksRef } from "../firebase";

export default function MarkName(props) {
  const { markId } = props;

  const { data } = useSubscription(markId && marksRef.doc(markId));

  if (!data) return null;

  const { name } = data;

  return name || "Unnamed mark";
}
