import React, { memo } from "react";
import "styled-components/macro";
import formatDuration from "format-duration";
import { useParams } from "react-router-dom";
import { HTMLTable, Button, NonIdealState, Tag } from "@blueprintjs/core";
import { history } from "../constants";
import { marksRef } from "../firebase";
import FadeIn from "../ui/FadeIn";
import LiveEditableText from "../ui/LiveEditableText";
import { renameMark } from "../actions";
import MarkStarRating from "../mark/MarkStarRating";
import { markUrl } from "../utils";

function MarksTableRenderer_(props) {
  const {
    sourceId,
    workspaceId,
    acrossSources,
    data,
    showDiscoverer,
    goToLocation,
    marksMeta,
    editable,
    showTags,
    order
  } = props;

  const { markId: selectedMarkId } = useParams();

  return (
    <FadeIn>
      {!data || !Object.keys(data).length ? (
        <div style={{ marginTop: "5em" }}>
          <NonIdealState
            icon="bookmark"
            description={
              acrossSources
                ? "Rate some marks in different sources to make them visible here."
                : "No marks added yet. You can add marks from the player."
            }
          />
        </div>
      ) : (
        <HTMLTable
          interactive
          style={{}}
          css={`
            border: 0;
            width: 100%;
            // table-layout: fixed;
            // overflow-wrap: break-word;

            td {
              // overflow: hidden;
              vertical-align: baseline !important;
            }

            col.actions {
              width: 5em;
            }

            col.star-rating {
              width: 8em;
            }

            col.discovered-by {
              width: 3em;
            }

            @media (min-width: 500px) {
              button,
              a[role="button"],
              .hover-reveal {
                opacity: 0;
              }

              tr:hover button,
              tr:hover a[role="button"],
              tr:hover .hover-reveal {
                opacity: 1;
              }
            }

            @media (max-width: 650px) {
              .start-time {
                display: none;
              }
            }

            @media (max-width: 490px) {
              .star-rating {
                display: none;
              }
            }
          `}
        >
          <colgroup>
            <col span="1" style={{ width: "2em" }} />
            <col span="1" />
            {showTags && <col span="1" style={{ width: "12em" }} />}
            <col span="1" className="start-time" style={{ width: "2em" }} />
            {!acrossSources && <col span="1" style={{ width: "2em" }} />}
            <col className="star-rating" span="1" />
            {showDiscoverer && <col className="discovered-by" span="1" />}
          </colgroup>

          <tbody>
            {(order || Object.keys(data)).map((markId, i) => {
              const {
                name,
                time,
                length,
                createdByName,
                rating = 0,
                sourceId: markSourceId,
                sourceName,
                tags
              } = data[markId];

              const { isPlaying } = marksMeta[markId];

              const markRef = marksRef.doc(markId);

              const active = markId === selectedMarkId;

              return (
                <tr
                  key={markId}
                  title="Go to mark"
                  onClick={() =>
                    history.push(markUrl(workspaceId, markSourceId, markId))
                  }
                >
                  <td>
                    <Button
                      large
                      disabled={isPlaying}
                      icon={isPlaying ? "volume-up" : "play"}
                      title="Play"
                      onClick={event => {
                        event.stopPropagation();
                        goToLocation(workspaceId, markSourceId, time, true);
                      }}
                      style={{
                        opacity: 1,
                        marginTop: acrossSources ? "1em" : null
                      }}
                    />
                  </td>
                  <td
                  // style={{
                  //   borderLeft:
                  //     markSourceId === sourceId
                  //       ? "4px solid " + indexToColor(i)
                  //       : null
                  // }}
                  >
                    <span
                      onClick={event => editable && event.stopPropagation()}
                      title={editable ? "Rename mark" : null}
                      css={`
                        font-weight: 600;
                      `}
                      style={{
                        backgroundColor: active ? "#333" : null,
                        color: active ? "#eee" : null,
                        padding: active ? "4px 7px" : null,
                        borderRadius: active ? "4px" : null
                      }}
                    >
                      {editable ? (
                        <LiveEditableText
                          confirmOnEnterKey
                          multiline
                          placeholder="Enter name..."
                          defaultValue={name}
                          onConfirm={name =>
                            renameMark(markRef, markSourceId, rating, name)
                          }
                        />
                      ) : (
                        name
                      )}
                    </span>

                    {acrossSources && markSourceId !== sourceId && (
                      <>
                        <br />
                        <span
                          style={{
                            color: "#ccc"
                          }}
                        >
                          {sourceName}
                        </span>
                      </>
                    )}
                  </td>

                  {showTags && (
                    <td style={{ textAlign: "right" }}>
                      {tags &&
                        tags.map(tag => (
                          <Tag
                            // className="hover-reveal"
                            key={tag}
                            minimal
                            css={`
                              margin: 0 0.5em 0.5em 0;
                            `}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </td>
                  )}

                  <td>
                    {!!length && (
                      <span style={{ marginLeft: "1em" }}>
                        {formatDuration(length * 1000)}
                      </span>
                    )}
                  </td>

                  {!acrossSources && (
                    <td className="start-time">
                      {formatDuration(time * 1000)}
                    </td>
                  )}

                  <td className="star-rating">
                    <MarkStarRating
                      markId={markId}
                      markData={data[markId]}
                      editable={editable}
                    />
                  </td>

                  {showDiscoverer && (
                    <td style={{}}>
                      <Tag minimal>{createdByName}</Tag>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      )}
    </FadeIn>
  );
}

export default memo(MarksTableRenderer_);
