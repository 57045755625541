import React, { useContext } from "react";
import "styled-components/macro";
import pluralize from "pluralize";
import { Icon, Tag, EditableText, Button } from "@blueprintjs/core";
import formatDuration from "format-duration";
import {
  marksRef,
  db,
  FieldValue,
  sourcesRef,
  workspacesRef
} from "../firebase";
import MarkStarRating from "./MarkStarRating";
import { toast, history } from "../constants";
import { PlayerContext, PlayerTimeContext } from "../contexts";
import TagsInput from "../shared/tags/TagsInput";
import { useSubscription } from "react-firehook";

export default function MarkEditor(props) {
  const { markId, markData } = props;
  const { getTime, loadedSourceId } = useContext(PlayerContext);
  const playerTime = useContext(PlayerTimeContext);

  const {
    name,
    time,
    length,
    createdByName,
    chatsCount,
    description,
    sourceId,
    workspaceId,
    rating,
    tags = []
  } = markData;

  const workspace = useSubscription(workspacesRef.doc(workspaceId));

  const tagUsageCounts = workspace.data && workspace.data.tagUsageCounts;
  const availableTags =
    tagUsageCounts &&
    Object.keys(tagUsageCounts).filter(
      tag => tagUsageCounts[tag] && (!tags || !tags.includes(tag))
    );

  return (
    <div
      css={`
        margin: 0 1em 0 0;
        flex: 1;
        min-width: 16em;
      `}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <header
          css={`
            padding: 1em 1em 0em;
          `}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "1em",
              color: "#aaa",
              alignItems: "flex-start"
            }}
          >
            <div
              css={`
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 1em;
              `}
            >
              <Tag minimal style={{ marginRight: "0.5em" }}>
                {createdByName}
              </Tag>
              <Icon icon="bookmark" />
              <span title="Time" style={{ paddingRight: "0.5em" }}>
                {formatDuration(time * 1000)}
              </span>

              {!!chatsCount && (
                <>
                  <Icon icon="chat" style={{ paddingRight: "0.5em" }} />
                  <span title={pluralize("comment", chatsCount, true)}>
                    {chatsCount}
                  </span>
                </>
              )}

              <div style={{ flexGrow: 1, marginLeft: "0.5em" }} />
              <MarkStarRating
                editable
                markId={markId}
                markData={markData}
                iconSize={20}
              />
            </div>
          </div>
        </header>

        <div
          style={{
            padding: "0 1em 1em"
          }}
        >
          <TagsInput
            items={availableTags || []}
            onItemSelect={async tag => {
              const batch = db.batch();

              batch.update(marksRef.doc(markId), {
                tags: FieldValue.arrayUnion(tag)
              });

              batch.update(workspacesRef.doc(workspaceId), {
                ["tagUsageCounts." + tag]: FieldValue.increment(1)
              });

              await batch.commit();
            }}
            onItemRemove={async tag => {
              const batch = db.batch();

              batch.update(marksRef.doc(markId), {
                tags: FieldValue.arrayRemove(tag)
              });

              batch.update(workspacesRef.doc(workspaceId), {
                ["tagUsageCounts." + tag]: FieldValue.increment(-1)
              });

              await batch.commit();
            }}
            selectedItems={tags}
          />
        </div>

        <div
          style={{
            padding: "1em"
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-end"
            }}
          >
            <Button
              large
              intent="danger"
              title="Delete mark"
              icon="trash"
              style={{ margin: "0 0.5em 0.5em 0" }}
              onClick={async event => {
                if (
                  window.confirm(
                    "Sure you want to delete " + (name || "this mark") + "?"
                  )
                ) {
                  const batch = db.batch();

                  batch.delete(marksRef.doc(markId));

                  const counts = {
                    marksCount: FieldValue.increment(-1)
                  };

                  batch.update(sourcesRef.doc(sourceId), {
                    ...counts,
                    ["marksByRating." + rating]: FieldValue.arrayRemove(markId),
                    ["markNames." + markId]: null
                  });
                  batch.update(workspacesRef.doc(workspaceId), {
                    ...counts
                  });

                  await batch.commit();

                  toast({
                    message: "Mark deleted",
                    intent: "success"
                  });

                  history.replace("..");
                }
              }}
            />

            <Button
              large
              minimal
              icon="alignment-left"
              title="Set beginning to current time"
              disabled={sourceId !== loadedSourceId}
              style={{ margin: "0 0.5em 0.5em 0" }}
              onClick={event => {
                event.stopPropagation();
                const newTime = getTime();
                if (
                  window.confirm(
                    "Sure you want to set " +
                      (name || "this mark") +
                      " to point to " +
                      formatDuration(newTime * 1000) +
                      "?"
                  )
                ) {
                  let newLength = null;
                  if (length) {
                    newLength = length + (time - newTime);
                    if (newLength < 0) newLength = null;
                  }

                  marksRef.doc(markId).update({
                    time: newTime,
                    length: newLength
                  });
                }
              }}
            />

            <Button
              large
              minimal
              icon="alignment-right"
              title="Set end to current time"
              disabled={sourceId !== loadedSourceId || playerTime < time}
              style={{ margin: "0 0.5em 0.5em 0" }}
              onClick={event => {
                const offset = getTime() - time;
                event.stopPropagation();
                if (offset < 0) return;
                if (
                  window.confirm(
                    "Sure you want to set the length of " +
                      (name || "this mark") +
                      " to be " +
                      formatDuration(offset * 1000) +
                      "?"
                  )
                ) {
                  marksRef.doc(markId).update({ length: offset });
                }
              }}
            />
          </div>

          <EditableText
            key={markId}
            multiline
            placeholder="Add description&hellip;"
            defaultValue={description}
            onConfirm={description =>
              marksRef.doc(markId).update({ description })
            }
            css={`
              padding: 1em 0 2em;
            `}
          />
        </div>
      </div>
    </div>
  );
}
