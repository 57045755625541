import React from "react";

export default function Hourglass() {
  return (
    <span
      role="img"
      aria-label=""
      style={{
        fontSize: "4em",
        opacity: "0.5"
      }}
    >
      ⌛
    </span>
  );
}
