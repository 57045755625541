import React, { useContext } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { Button, Popover, Menu, MenuItem, Icon } from "@blueprintjs/core";
import { Route, Link, Switch, useRouteMatch } from "react-router-dom";
import { keyframes } from "styled-components";
import { getUserPhotoOrGravatar, useWindowDimensions } from "@josteinbe/reager";
import { auth, workspacesRef } from "../../firebase";
import { UserContext } from "../../contexts";
import { history } from "../../constants";
import WorkspacePicker from "./WorkspacePicker";
import { CrumbNavLink } from "./NavItems";
import SourceName from "../../source/SourceName";
import { useSubscription } from "react-firehook";
import MarkName from "../../mark/MarkName";

function SourceTopCrumbs() {
  const {
    url,
    path,
    params: { workspaceId, sourceId }
  } = useRouteMatch();

  const { data } = useSubscription(workspacesRef.doc(workspaceId));

  return (
    <>
      <CrumbNavLink
        to={"/workspaces/" + workspaceId}
        text={data ? data.name || "Unnamed workspace" : ""}
      />
      <Icon style={{ margin: "0 0.5em" }} icon="chevron-right" />
      <CrumbNavLink to={url}>
        <SourceName sourceId={sourceId} />
      </CrumbNavLink>
      <Route
        path={path + "/marks/:markId"}
        render={({
          match: {
            url,
            params: { markId }
          }
        }) => (
          <>
            <Icon style={{ margin: "0 0.5em" }} icon="chevron-right" />
            <CrumbNavLink isActive={() => true} to={url}>
              <MarkName markId={markId} />
            </CrumbNavLink>
          </>
        )}
      />
    </>
  );
}

export default function NavbarTop() {
  return (
    <NavbarTopStyle>
      <DogHead />
      <Switch>
        <Route
          path={"/workspaces/:workspaceId/sources/:sourceId"}
          component={SourceTopCrumbs}
        />
        <Route path="/workspaces/:workspaceId" component={WorkspacePicker} />
        <Route>
          <CrumbNavLink isActive={() => true} to="/" text="TagDog" />
        </Route>
      </Switch>
      <div style={{ flexGrow: "1" }}></div>
      <ProfileDropdownButton />
    </NavbarTopStyle>
  );
}

const wagging = keyframes`
  0% { transform: rotate(-10deg); }
  100% { transform: rotate(10deg); }
`;

const DogHead = () => (
  <Link
    to="/"
    css={`
      transform: center center;
      margin-right: 1em;
      &:hover {
        animation: ${wagging} 1s ease-in-out infinite alternate;
        animation-delay: -0.5s;
        text-decoration: none;
      }
      @media (max-width: 850px) {
        display: none;
      }
    `}
  >
    <span
      title="TagDog"
      role="img"
      aria-label="dog"
      style={{ fontSize: "2em" }}
    >
      🐶
    </span>
  </Link>
);

const NavbarTopStyle = styled.div`
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #f7f7f7;
`;

function ProfileDropdownButton() {
  const user = useContext(UserContext);
  const photoUrl = getUserPhotoOrGravatar(user);
  const { width: windowWidth } = useWindowDimensions();

  return (
    <Popover
      minimal
      position="bottom-right"
      content={
        <Menu large>
          <MenuItem text="Account" onClick={() => history.push("/account")} />
          <MenuItem
            text="Sign out"
            onClick={() => {
              auth.signOut();
              history.push("/");
            }}
          />
        </Menu>
      }
    >
      <Button
        large
        minimal
        icon={
          <img
            alt="Profile"
            src={photoUrl}
            css={`
              max-width: 40px;
              border-radius: 20px;
            `}
          />
        }
        rightIcon={windowWidth > 600 ? "chevron-down" : null}
        style={{ margin: "0 0 1em 0" }}
        title={user.email}
      />
    </Popover>
  );
}
