import React, { useContext } from "react";
import "styled-components/macro";
import { ButtonGroup, Button, Slider, Popover } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import queryString from "query-string";
import copy from "copy-to-clipboard";
import { useWindowDimensions } from "@josteinbe/reager";
import {
  PlayerContext,
  PlayerVolumeContext,
  UserProfileContext,
  UserContext
} from "../../contexts";
import {
  sourcesRef,
  db,
  marksRef,
  workspacesRef,
  FieldValue
} from "../../firebase";
import { history } from "../../constants";
import { toast } from "../../constants";
import { baseUrl, sourceUrl } from "../../utils";
import MarksViz from "../../source/MarksViz";
import PlayerProgress from "./PlayerProgress";
import CurrentlyPlayingField from "./CurrentlyPlayingField";
import CurrentlyPlayingMarkProgress from "./CurrentlyPlayingMarkProgress";
import { markUrl } from "../../utils";

export default function PlayerControls(props) {
  const {
    audioRef,
    playing,
    setVolume,
    loadedSourceId,
    loadedSourceWorkspaceId,
    getTime
  } = useContext(PlayerContext);

  const volume = useContext(PlayerVolumeContext);

  const { displayName } = useContext(UserProfileContext);
  const { uid } = useContext(UserContext);

  const sourceRef = loadedSourceId && sourcesRef.doc(loadedSourceId);

  const source = useSubscription(sourceRef);

  const { width: windowWidth } = useWindowDimensions();

  const largeButtons = windowWidth > 430;

  if (!source.ready)
    return loadedSourceId ? (
      <BottomBar largeButtons={largeButtons}>Loading source...</BottomBar>
    ) : null;

  const { length } = source.data;

  return (
    <BottomBar largeButtons={largeButtons}>
      <div style={{ display: "flex" }}>
        <ButtonGroup
          large={largeButtons}
          disabled={!length}
          style={{ margin: "0 1em 1em 0" }}
        >
          <CurrentlyPlayingField />
          {windowWidth > 390 && (
            <Button
              icon="link"
              title="Copy link to current time"
              onClick={event => {
                const shareableUrl =
                  baseUrl +
                  sourceUrl(loadedSourceWorkspaceId, loadedSourceId) +
                  "?" +
                  queryString.stringify({ t: getTime() });
                copy(shareableUrl);
                toast({
                  message: "Link copied to clipboard",
                  intent: "success"
                });
              }}
            />
          )}
        </ButtonGroup>

        <ButtonGroup
          large={largeButtons}
          disabled={!length}
          style={{ margin: "0 1em 1em 0" }}
        >
          <Button
            intent="primary"
            icon="bookmark"
            // intent="primary"
            title="Place mark"
            onClick={async () => {
              const batch = db.batch();

              const doc = marksRef.doc();
              batch.set(doc, {
                sourceId: loadedSourceId,
                sourceName: source.data.name,
                time: getTime(),
                name: "",
                createdByUid: uid,
                createdByName: displayName,
                createdTime: new Date(),
                workspaceId: loadedSourceWorkspaceId,
                rating: 0,
                chatsCount: 0
              });

              batch.update(sourcesRef.doc(loadedSourceId), {
                marksCount: FieldValue.increment(1)
              });

              batch.update(workspacesRef.doc(loadedSourceWorkspaceId), {
                marksCount: FieldValue.increment(1)
              });

              await batch.commit();

              history.push(
                markUrl(loadedSourceWorkspaceId, loadedSourceId, doc.id)
              );
            }}
          />
        </ButtonGroup>

        <ButtonGroup
          large={largeButtons}
          disabled={!length}
          style={{ margin: "0 1em 1em 0" }}
        >
          <Button
            icon="fast-backward"
            text={windowWidth > 800 && "30s"}
            onClick={() => {
              const newTime = Math.max(0, getTime() - 30);
              audioRef.current.currentTime = newTime;
            }}
          />
          <Button
            icon="fast-backward"
            text={windowWidth > 900 && "5s"}
            onClick={() => {
              const newTime = Math.max(0, getTime() - 5);
              audioRef.current.currentTime = newTime;
            }}
          />
          <Button
            intent={playing ? "warning" : null}
            icon={playing ? "pause" : "play"}
            onClick={() =>
              playing ? audioRef.current.pause() : audioRef.current.play()
            }
          />
          <Button
            icon="fast-forward"
            text={windowWidth > 900 && "5s"}
            onClick={() => {
              const newTime = Math.min(length, getTime() + 5);
              audioRef.current.currentTime = newTime;
            }}
          />
          <Button
            icon="fast-forward"
            text={windowWidth > 800 && "30s"}
            onClick={() => {
              const newTime = Math.min(length, getTime() + 30);
              audioRef.current.currentTime = newTime;
            }}
          />
        </ButtonGroup>

        <Popover
          minimal
          position="top"
          content={
            <div
              css={`
                padding: 1em 0 1em 1em;
                background-color: #293742;
              `}
            >
              <Slider
                vertical
                labelRenderer={false}
                max={1.0}
                stepSize={0.01}
                value={volume}
                onChange={value => setVolume(value)}
              />
            </div>
          }
        >
          <Button large={largeButtons} icon="volume-up" />
        </Popover>

        {windowWidth > 600 && (
          <div
            css={`
              flex-grow: 1;
              padding: 8px 0 0 1em;
              color: black;
            `}
          >
            <CurrentlyPlayingMarkProgress />
          </div>
        )}
      </div>
    </BottomBar>
  );
}

function BottomBar(props) {
  const { children, largeButtons } = props;
  const { loadedSourceWorkspaceId, loadedSourceId } = useContext(PlayerContext);

  return (
    <div
      className="bp3-dark"
      css={`
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: ${largeButtons ? 5 : 4.2}em;
        border-top: 1px solid #eee;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
        background-color: white;
        background-color: #293742;
      `}
    >
      <div
        css={`
          opacity: 0.1;
        `}
      >
        <PlayerProgress
          compact
          sourceId={loadedSourceId}
          workspaceId={loadedSourceWorkspaceId}
        />
      </div>
      <div
        css={`
          margin-top: -10px;
          opacity: 0.5;
        `}
      >
        <MarksViz
          compact
          workspaceId={loadedSourceWorkspaceId}
          sourceId={loadedSourceId}
        />
      </div>
      <div
        css={`
          padding: 10px 1em 0;
        `}
      >
        {children}
      </div>
    </div>
  );
}
