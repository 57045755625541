import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { workspacesRef, FieldValue } from "../firebase";
import PageNotFound from "../ui/PageNotFound";
import { UserContext, UserProfileContext } from "../contexts";
import { toast } from "../constants";

export default function WorkspaceJoiner(props) {
  const { workspaceId, invitationCode } = useParams();
  const { uid } = useContext(UserContext);
  const { displayName } = useContext(UserProfileContext);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (!displayName) return;

    async function updateWorkspace() {
      try {
        await workspacesRef.doc(workspaceId).update({
          memberUids: FieldValue.arrayUnion(uid),
          ["memberNames." + uid]: displayName,
          invitationCode
        });
        toast({
          message: "Workspace joined",
          intent: "success"
        });
        if (mounted) setSuccess(true);
      } catch (error) {
        console.log(error);
        if (mounted) setSuccess(false);
      }
    }

    updateWorkspace();

    return () => (mounted = false);
  }, [workspaceId, invitationCode, uid, displayName]);

  if (success === true) return <Redirect to={"/workspaces/" + workspaceId} />;
  if (success === false) return <PageNotFound />;
  return null;
}
