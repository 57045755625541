import React, { useContext } from "react";
import { Button, Icon, H1 } from "@blueprintjs/core";
import "styled-components/macro";
import MarkEditor from "./MarkEditor";
import { useParams } from "react-router-dom";
import { useSubscription } from "react-firehook";
import { sourcesRef, workspacesRef, marksRef, FieldValue } from "../firebase";
import { PlayerContext, PlayerTimeContext } from "../contexts";
import { history } from "../constants";
import FadeIn from "../ui/FadeIn";
import ProgressField from "../ui/ProgressField";
import ChatHistory from "../chat/ChatHistory";
import Waveform from "../source/progress/Waveform";
import Helmet from "react-helmet";

export default function Mark() {
  const { markId, workspaceId, sourceId } = useParams();
  const { goToLocation, loadedSourceId } = useContext(PlayerContext);
  const playerTime = useContext(PlayerTimeContext);

  const source = useSubscription(sourcesRef.doc(sourceId));
  const workspace = useSubscription(workspacesRef.doc(workspaceId));
  const marks = useSubscription(
    marksRef
      .where("sourceId", "==", sourceId)
      .where("workspaceId", "==", workspaceId)
      .orderBy("time")
  );

  if (!workspace.ready || !marks.ready) return null;

  const { memberUids } = workspace.data;

  const markData = marks.data[markId];

  if (!markData) return null; // happens for a short while when deleting mark

  const {
    name,
    time,
    length,
    sourceId: markSourceId,
    workspaceId: markWorkspaceId
  } = markData;

  const markIds = Object.keys(marks.data);

  const markIndex = markIds.indexOf(markId);

  const markIsPlaying =
    markSourceId === loadedSourceId &&
    playerTime >= time &&
    playerTime < time + length;

  return (
    <FadeIn>
      <div
        css={`
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0 1em;
          max-width: 1200px;
          margin: 0 auto 0;
        `}
      >
        {!loadedSourceId && source.data && workspace.data && markData && (
          <Helmet>
            <title>
              {markData.name +
                " – " +
                source.data.name +
                " – " +
                workspace.data.name +
                " | TagDog"}
            </title>
          </Helmet>
        )}

        <H1
          css={`
            font-size: 2em !important;
            @media (max-width: 720px) {
              font-size: 1.5em !important;
            }
            @media (max-width: 520px) {
              font-size: 1.1em !important;
            }

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
            padding: 0 0 0.3em 0;

            /* flex: 1; */
            /* vertical-align: baseline; */
            cursor: pointer;

            ${name ? "" : "color: gray;"}
          `}
          title="Edit..."
          onClick={() => {
            const newName = prompt("Enter new source name", name);
            if (newName) marksRef.doc(markId).update({ name: newName });
          }}
        >
          {name || <>Name this mark&hellip;</>}
        </H1>

        <div
          css={`
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 1em;
          `}
        >
          <Button
            minimal
            onClick={() => goToLocation(workspaceId, sourceId, time, true)}
            title={markIsPlaying ? "Mark is playing" : "Play mark"}
            disabled={markIsPlaying}
          >
            <Icon iconSize={30} icon={markIsPlaying ? "volume-up" : "play"} />
          </Button>
          <Button
            minimal
            onClick={() => history.replace(markIds[markIndex - 1])}
            title="Previous mark"
          >
            <Icon iconSize={30} icon="chevron-left" />
          </Button>
          <Button
            minimal
            large
            title="Next mark"
            onClick={() => history.replace(markIds[markIndex + 1])}
          >
            <Icon iconSize={30} icon="chevron-right" />
          </Button>
        </div>
      </div>

      <div
        css={`
          margin: 0 auto 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-around;
          max-width: 1200px;
          padding: 0 0em;
        `}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        ></div>

        <div
          css={`
            padding: 0 1em 1em;
            display: flex;
            flex-wrap: wrap-reverse;
          `}
        >
          <div
            css={`
              flex-grow: 1;
              min-width: 220px;
            `}
          >
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", width: "100%", top: "7px" }}>
                <ProgressField
                  noLabels
                  transparent
                  onClick={markRelativeTime =>
                    goToLocation(
                      markWorkspaceId,
                      markSourceId,
                      time + markRelativeTime,
                      true
                    )
                  }
                  length={length}
                  time={playerTime - time}
                  height="30px"
                  active={markIsPlaying}
                  clickToPlayText={`Click to play "${name}"...`}
                />
              </div>

              <div style={{ opacity: "0.2", marginBottom: "2em" }}>
                <Waveform
                  height={50}
                  colorIndex={markIndex}
                  startTime={time}
                  endTime={length ? time + length : null}
                  onClick={time =>
                    goToLocation(markWorkspaceId, markSourceId, time, true)
                  }
                />
              </div>
              <Waveform
                height={20}
                colorizeMarks
                onMarkClick={markId => history.replace(markId)}
                onClick={time =>
                  goToLocation(markWorkspaceId, markSourceId, time, true)
                }
                highlightStart={time}
                highlightEnd={time + length}
              />
            </div>
          </div>
        </div>

        <div
          css={`
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 800px) {
              display: block;
            }
          `}
        >
          <MarkEditor key={markId} markId={markId} markData={markData} />
          <ChatHistory
            chatId={markId}
            audienceUids={memberUids}
            onSay={() => {
              marksRef
                .doc(markId)
                .update({ chatsCount: FieldValue.increment(1) });
            }}
          />
        </div>
      </div>
    </FadeIn>
  );
}
