import React, { useContext } from "react";
import { useSubscription } from "react-firehook";
import { sourcesRef } from "../../firebase";
import { PlayerContext, PlayerTimeContext } from "../../contexts";
import ProgressField from "../../ui/ProgressField";

export default function PlayerProgress(props) {
  const { workspaceId, sourceId, compact } = props;
  const sourceRef = sourcesRef.doc(sourceId);

  const { loadedSourceId, goToLocation, playing } = useContext(PlayerContext);
  const time = useContext(PlayerTimeContext);

  const { ready, data } = useSubscription(sourceRef);

  if (!ready) return null;

  const { length } = data;

  const height = compact ? "10px" : "30px";

  return (
    <ProgressField
      onClick={newTime => goToLocation(workspaceId, sourceId, newTime, true)}
      compact={compact}
      length={length}
      time={time}
      height={height}
      active={loadedSourceId === sourceId && (time || playing)}
    />
  );
}
