import React, { useEffect, useContext } from "react";
import "styled-components/macro";
import { H4, Tag, Card, Icon } from "@blueprintjs/core";
import formatDuration from "format-duration";
import { sourcesRef, marksRef } from "../firebase";
import { history } from "../constants";
import { PlayerContext } from "../contexts";
import { fadeInAnimation } from "../ui/FadeIn";

export default function SourceCard(props) {
  const {
    sourceId,
    sourceData: {
      name,
      description,
      timestamp,
      length,
      uploadedByName,
      workspaceId,
      hue,
      marksCount,
      markNames = {},
      marksByRating = {},
      backgroundImage
    },
    emphasized,
    interactive,
    fadeDelay
  } = props;

  const { goToLocation } = useContext(PlayerContext);

  useEffect(() => {
    // TODO: migration - remove
    if (!hue)
      sourcesRef.doc(sourceId).update({
        hue: Math.floor(Math.random() * 360)
      });
  }, [hue, sourceId]);

  const url = "/workspaces/" + workspaceId + "/sources/" + sourceId;

  const markHighlights = Object.keys(marksByRating)
    .filter(ratingStr => parseInt(ratingStr) > 3)
    .sort((a, b) => parseInt(b) - parseInt(a))
    .reduce(
      (acc, ratingStr) => [
        ...acc,
        ...(marksByRating[ratingStr] || []).map(markId => ({
          markId,
          rating: parseInt(ratingStr)
        }))
      ],
      []
    );

  return (
    <Card
      interactive={interactive}
      elevation={emphasized ? 4 : 2}
      css={`
        width: 25em;
        margin: 1em;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        ${emphasized ? "transform: scale(1.1);" : ""}

        @media (max-width: 500px) {
          max-width: 90%;
          h4 {
            max-width: 10em !important;
          }
        }

        ${backgroundImage && backgroundImage.url
          ? "background: url(" + backgroundImage.url + " ) center no-repeat;"
          : ""}
        background-size: cover;

        opacity: 0;
        animation: ${fadeInAnimation} ${props => props.length || 0.5}s ease
          normal forwards running;

        animation-delay: ${fadeDelay || 0}s;
      `}
      title={interactive ? "Go to timeline" : null}
      onClick={() => interactive && history.push(url)}
    >
      <header
        // className="bp3-dark"
        css={`
          border-radius: 3px 3px 0 0;
          background-color: ${backgroundImage
            ? "#eee"
            : `hsl(${hue}, 20%, 90%)`};
          padding: 1em 1em 0em;
          opacity: 0.9;
        `}
      >
        <div
          style={{
            display: "flex"
          }}
        >
          <H4 style={{ maxWidth: "14em", flexShrink: 1 }}>{name}</H4>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
              color: "#999"
            }}
          >
            <span>{timestamp && timestamp.toDate().toLocaleDateString()}</span>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: "1em", color: "gray" }}>
          <div>
            Uploaded by <Tag minimal>{uploadedByName}</Tag>
          </div>
          <div style={{ flexGrow: 1 }} />
          {!!marksCount && (
            <div style={{ marginRight: "1em" }} title={marksCount + " marks"}>
              {marksCount} <Icon icon="bookmark" />
            </div>
          )}
          {length && (
            <div title="Audio length">{formatDuration(length * 1000)}</div>
          )}
        </div>
      </header>

      <div
        css={`
          background-color: ${backgroundImage
            ? "#fff"
            : `hsl(${hue}, 80%, 98%)`};
          opacity: 0.9;
          padding: 1em;
          flex-grow: 1;
          border-radius: 0 0 3px 3px;
        `}
      >
        {!!description && (
          <div style={{ marginBottom: "1em", color: "gray" }}>
            {description}
          </div>
        )}

        {markHighlights.map(({ markId, rating }) => {
          return (
            <Tag
              key={markId}
              minimal
              interactive
              title="Play this mark"
              style={{
                margin: "0 0.4em 0.4em 0",
                border: rating > 4 ? "1px solid gold" : null
              }}
              onClick={async event => {
                event.stopPropagation();
                const mark = await marksRef.doc(markId).get();
                const { sourceId, time } = mark.data();
                goToLocation(workspaceId, sourceId, time, true);
              }}
            >
              {markNames[markId] || "🐶"}
            </Tag>
          );
        })}
        {!markHighlights.length && (
          <p style={{ color: "lightgray" }}>
            Top rated marks would show up here if there were any.
          </p>
        )}
      </div>
    </Card>
  );
}
