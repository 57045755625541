import React, { useContext, useState } from "react";
import "styled-components/macro";
import { useSubscription } from "react-firehook";
import TimeAgo from "react-timeago";
import { chatMessagesRef, FieldValue } from "../firebase";
import { InputGroup, Tag } from "@blueprintjs/core";
import { UserContext, UserProfileContext } from "../contexts";

export default function ChatHistory(props) {
  const { chatId, audienceUids, onSay, placeholder } = props;
  const user = useContext(UserContext);
  const { displayName } = useContext(UserProfileContext);

  const chatMessages = useSubscription(
    chatMessagesRef
      .where("chatId", "==", chatId)
      .orderBy("time", "desc")
      .limit(50)
  );

  const [inputText, setInputText] = useState("");

  const messageIds = chatMessages.data ? Object.keys(chatMessages.data) : [];

  if (!audienceUids) throw new Error("expected audienceUids");

  return (
    <div
      css={`
        flex: 1;
        padding: 1em;
      `}
    >
      <InputGroup
        large
        leftIcon="comment"
        placeholder={placeholder || "Comment..."}
        value={inputText}
        onChange={event => setInputText(event.target.value)}
        onKeyDown={event => {
          if (event.key === "Enter") {
            if (!inputText) return;
            setInputText("");
            chatMessagesRef.doc().set({
              chatId,
              authorUid: user.uid,
              authorName: displayName,
              audienceUids,
              time: FieldValue.serverTimestamp(),
              text: event.target.value
            });
            onSay && onSay(event.target.value);
          }
        }}
        css={`
          margin-bottom: 1em;
        `}
      />
      {!!messageIds.length && (
        <div
          css={`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={`
              flex-grow: 1;
            `}
          ></div>

          {!!messageIds.length &&
            messageIds.map((chatMessageId, i) => {
              const { text, authorName, time } = chatMessages.data[
                chatMessageId
              ];

              return (
                <div
                  key={chatMessageId}
                  css={`
                    margin: 2em 1em 1em 0;
                  `}
                >
                  <div
                    css={`
                      margin-bottom: 1em;
                      font-size: 17px;
                      font-weight: 600;
                    `}
                    ref={element => {
                      // element &&
                      // messageIds.length - 1 === i &&
                      // (element.parentNode.parentNode.scrollTop =
                      //   element.offsetTop)
                    }}
                  >
                    {text}
                  </div>
                  <div
                    css={`
                      // text-align: right;
                    `}
                  >
                    <Tag
                      minimal
                      css={`
                        font-size: 0.9em;
                        margin-bottom: 1em;
                      `}
                    >
                      {authorName} &middot;{" "}
                      {!!time &&
                        (new Date() - time.toDate() < 60000 ? (
                          "Just now"
                        ) : (
                          <TimeAgo date={time.toDate()} />
                        ))}
                    </Tag>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
