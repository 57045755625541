import React, { useState, useCallback } from "react";
import "styled-components/macro";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { SettingsCard } from "./settingsStyles";
import { SettingsFormContext } from "../../contexts";
import { SubHeader } from "../headers";

export default function SettingsSection(props) {
  const { id, onSave, defaultValue, children, header } = props;
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const handleSave = useCallback(() => {
    onSave(value);
    setEditing(false);
  }, [onSave, value, setEditing]);

  return (
    <SettingsFormContext.Provider
      value={{ editing, setEditing, defaultValue, value, setValue, handleSave }}
    >
      <SettingsCard id={id}>
        <SubHeader>{header}</SubHeader>
        <table
          css={`
            margin-top: 2em;

            td,
            th {
              padding: 1em 0.5em;
            }

            td:first-child,
            th:first-child {
              padding-left: 0;
            }
          `}
        >
          <tbody>{children}</tbody>
        </table>
        {editing ? (
          <ButtonGroup>
            <Button large text="Save" intent="primary" onClick={handleSave} />
            <Button
              large
              text="Cancel"
              onClick={() => {
                setValue(defaultValue);
                setEditing(false);
              }}
            />
          </ButtonGroup>
        ) : (
          <Button large text="Edit" onClick={() => setEditing(true)} />
        )}
      </SettingsCard>
    </SettingsFormContext.Provider>
  );
}
