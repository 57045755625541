import React, { useContext } from "react";
import { HTMLTable, Button, AnchorButton } from "@blueprintjs/core";
import { useSubscription } from "react-firehook";
import {
  db,
  workspacesRef,
  sourcesRef,
  marksRef,
  profilesRef,
  workspacesStorageRef
} from "../firebase";
import { UserContext } from "../contexts";
import CenteredSection from "../ui/CenteredSection";
import {
  HeaderSection,
  MainPane,
  MenuSection,
  MainSection,
  SettingsCard
} from "../ui/settings/settingsStyles";
import { Header, SubHeader } from "../ui/headers";
import SettingsSection from "../ui/settings/SettingsSection";
import SettingsStringInput from "../ui/settings/SettingsStringInput";
import { toast } from "../constants";

function DeletedWorkspaces(props) {
  const { uid } = useContext(UserContext);

  const { data } = useSubscription(
    workspacesRef
      .where("memberUids", "array-contains", uid)
      .where("deleted", "==", true)
  );

  if (!data || !Object.keys(data).length) return <p>Trashbin is empty.</p>;

  return (
    <HTMLTable>
      <tbody>
        {Object.keys(data).map(workspaceId => {
          const { name } = data[workspaceId];

          return (
            <tr key={workspaceId}>
              <td>
                {name || (
                  <span style={{ color: "#aaa" }}>Unnamed workspace</span>
                )}
              </td>
              <td>
                <Button
                  icon="undo"
                  text="Restore"
                  intent="success"
                  onClick={() => {
                    workspacesRef.doc(workspaceId).update({ deleted: false });
                  }}
                  style={{ marginRight: "1em" }}
                />
                <Button
                  icon="flame"
                  text="Destroy"
                  intent="danger"
                  onClick={async () => {
                    if (
                      window.confirm(
                        'Sure you want to destroy "' +
                          (name || "Unnamed workspace") +
                          '"?'
                      )
                    ) {
                      const sources = await sourcesRef
                        .where("workspaceId", "==", workspaceId)
                        .get();
                      const marks = await marksRef
                        .where("workspaceId", "==", workspaceId)
                        .get();
                      !sources.empty &&
                        sources.docs.forEach(doc => {
                          workspacesStorageRef
                            .child(workspaceId)
                            .child(doc.id)
                            .delete();
                          doc.ref.delete();
                        });
                      !marks.empty &&
                        marks.docs.forEach(doc => doc.ref.delete());
                      workspacesRef.doc(workspaceId).delete();
                    }
                  }}
                  style={{ marginRight: "1em" }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}

export default function Account(props) {
  const { uid } = useContext(UserContext);
  const profile = useSubscription(profilesRef.doc(uid));

  if (!profile.ready) return null;

  const { displayName } = profile.data;

  return (
    <CenteredSection>
      <HeaderSection>
        <Header>Account settings</Header>
        <p></p>
      </HeaderSection>

      <MainPane>
        <MenuSection>
          <AnchorButton minimal href="#profile" text="Profile" />
          {/* <AnchorButton minimal href="#settings" text="Experimental features" /> */}
          <AnchorButton minimal href="#trash" text="Trash" />
        </MenuSection>

        <MainSection>
          <SettingsSection
            id="profile"
            header="Profile"
            defaultValue={{ displayName }}
            onSave={async fields => {
              const batch = db.batch();

              profile.data
                ? batch.update(profile.ref, fields)
                : batch.set(profile.ref, fields);

              const workspaces = await workspacesRef
                .where("memberUids", "array-contains", uid)
                .get();

              const sourcePromises = [];

              workspaces.forEach(snap => {
                sourcePromises.push(
                  sourcesRef
                    .where("uploadedByUid", "==", uid)
                    .where("workspaceId", "==", snap.id)
                    .get()
                );

                batch.update(snap.ref, {
                  ["memberNames." + uid]: fields.displayName
                });
              });

              const sourceSnaps = await Promise.all(sourcePromises);

              sourceSnaps.forEach(colSnap => {
                colSnap.forEach(snap => {
                  batch.update(snap.ref, {
                    uploadedByName: fields.displayName
                  });
                });
              });

              await batch.commit();

              toast({
                message: "User name updated",
                intent: "success"
              });
            }}
          >
            <SettingsStringInput
              label="User name:"
              id="displayName"
              placeholder="User name..."
              autoFocus
            />
          </SettingsSection>

          {/* <SettingsCard id="settings">
            <SubHeader>Experimental features</SubHeader>
            <Switch
              large
              label="Show waveforms"
              checked={showWaveforms}
              onChange={() => {
                userSettings.data
                  ? userSettings.ref.update({ showWaveforms: !showWaveforms })
                  : userSettings.ref.set({ showWaveforms: !showWaveforms });
              }}
            />
          </SettingsCard> */}

          <SettingsCard id="trash">
            <SubHeader>Deleted workspaces</SubHeader>
            <DeletedWorkspaces />
          </SettingsCard>
        </MainSection>
      </MainPane>
    </CenteredSection>
  );
}
